/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import * as moment from 'moment';
import 'moment/locale/es';

import SECRETS from '../secrets';
import MapRuta from './MapRuta';
import RutaOptions from './RutaOptions';
import { MAXSPEED } from './options';

class RutaTokenScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: [],
      refMap: {}, // google map refs
      log: [],
      isParkedNotNull: true,
      loadingData: false,
      currentDayLoaded: false,
    };
  }

  componentDidMount() {
    moment.locale('es');
    const { match: { params: { token } } } = this.props;
    if (token) {
      this.getData(undefined, undefined);
    }
  }

  onMapMounted = (ref) => this.setState({ refMap: ref });

  reduceData = (array) => {
    if (array.length && array.length > 1) {
      return array.reduce((acc, log) => {
        if (!acc.length) {
          return [acc];
        }
        const last = acc.length - 1;
        if (acc[last].isParked === log.isParked) {
          // join them
          if (!log.isParked) {
            acc[last].distance += log.distance;
            acc[last].velocidadMax = Math.max(acc[last].velocidadMax, log.velocidadMax);
            acc[last].tiempoEncendido += log.tiempoEncendido;
          }
          acc[last].horaF = log.horaF;
          const tiempo = moment(acc[last].horaF, 'HH:mm:ss').diff(moment(acc[last].horaI, 'HH:mm:ss')) / 1000;
          const hrs = tiempo / 60 / 60;
          const min = (hrs - Math.floor(hrs)) * 60;
          const sec = (min - Math.floor(min)) * 60;
          acc[last].tiempo = `${Math.floor(hrs)}h ${Math.floor(min)}m ${Math.floor(sec)}s`;
          acc[last].rutas = [...acc[last].rutas, ...log.rutas];
          return acc;
        }
        return [...acc, log];
      }, array[0]);
    }
    return [];
  };

  parkedNotNull = (logs) => logs.every((log) => log.isParked !== null);

  getData = () => {
    const { match: { params: { token } } } = this.props;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    this.setState({ loadingData: true, currentDayLoaded: false });
    let reqBody = {};
    if (token) {
      reqBody = { token };
      axios.post('/reportes/rutas', reqBody)
        .then((resp) => {
          const log = resp.data;
          const isParkedNotNull = this.parkedNotNull(log);
          log.forEach((entry, it) => {
            if (!entry.isParked || (it > 1 && log[it - 1].isParked)) {
              if (it === log.length - 1 && log.length > 1 && isParkedNotNull) {
                log[it].isParked = !log[it - 1].isParked;
              }
            }
          });
          const filteredLog = log.filter((logNoZeroTime) => logNoZeroTime.tiempo !== '0h 0m 0s');
          let outLog = filteredLog;
          if (filteredLog.length > 2 && isParkedNotNull) {
            outLog = this.reduceData(filteredLog);
          }
          this.setState({
            log: outLog,
            isParkedNotNull,
            loadingData: false,
            currentDayLoaded: true,
          });
        })
        .catch(() => {
          this.setState({ loadingData: false });
        });
    }
  };

  rutaIsParked = (log) => log.isParked || (log.isParked === null && log.speedMax < MAXSPEED);

  setRuta = (log) => {
    const { refMap } = this.state;
    if (this.rutaIsParked(log)) {
      const path = [];
      path.push({ lat: log.rutas[0].latitud, lng: log.rutas[0].longitud });
      this.setState({ path });
      const center = { lat: log.rutas[0].latitud, lng: log.rutas[0].longitud };
      refMap.panTo(center);
    } else {
      const path = log.rutas.map((ruta) => {
        const googleCompatRuta = {
          lat: ruta.latitud,
          lng: ruta.longitud,
        };
        return googleCompatRuta;
      });
      const center = { lat: log.rutas[0].latitud, lng: log.rutas[0].longitud };
      refMap.panTo(center);
      this.setState({ path });
    }
  };

  setRutaAll = () => {
    const { log, refMap } = this.state;
    let path = [];
    if (log.length > 0) {
      log.forEach((entry) => {
        if (this.rutaIsParked(entry)) {
          path.push({ lat: entry.rutas[0].latitud, lng: entry.rutas[0].longitud });
        } else {
          path = [
            ...path,
            ...entry.rutas.map((ruta) => ({ lat: ruta.latitud, lng: ruta.longitud })),
          ];
        }
      });
      const center = { lat: path[0].lat, lng: path[0].lng };
      refMap.panTo(center);
      this.setState({ path });
    }
  };

  render() {
    const { unidad } = this.props;
    const { match: { params: { day, month, year } } } = this.props;
    let date;
    if (moment(`${year}-${month}-${day}`, 'YYYY-MM-DD', true).isValid()) {
      date = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
    }
    const {
      path,
      log,
      isParkedNotNull,
      loadingData,
      currentDayLoaded,
    } = this.state;
    return (
      <div>
        <div className="contenido">
          <div className="map-ruta">
            <MapRuta
              loadingElement={<div style={{ height: '100%' }} />}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${SECRETS.MAPSAPIKEY}`}
              containerElement={<div style={{ height: '50vh', marginTop: '0px' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              path={path}
              onMapMounted={this.onMapMounted}
            />
          </div>
          <div className="ruta-options">
            <RutaOptions
              data={log}
              getData={this.getData}
              loadingData={loadingData}
              currentDayLoaded={currentDayLoaded}
              setRuta={this.setRuta}
              unidad={unidad}
              isParkedNotNull={isParkedNotNull}
              setRutaAll={this.setRutaAll}
              selectorDisabled
              date={date}
            />
          </div>
        </div>
      </div>
    );
  }
}

RutaTokenScreen.propTypes = {
  unidad: PropTypes.shape({
    unidad: PropTypes.string,
    idUnidad: PropTypes.number,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }),
};

RutaTokenScreen.defaultProps = {
  match: {},
  unidad: {},
};

export default RutaTokenScreen;
