import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as Axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FA from 'react-fontawesome';

import SECRETS from '../secrets';

const axios = Axios.create({
  baseURL: SECRETS.SERVERURL,
});

const CELL_WIDTH = '150px';

const container = {
  height: '100vh',
  width: '100vw',
  backgroundColor: '#fff',
  color: '#848484',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const phase1 = {
  width: '100%',
  height: '100%',
};

const table = {
  display: 'block',
  width: '99vw',
  height: '85vh',
  overflow: 'scroll',
  scrollbarColor: '#ccc #333',
  scrollbarWidth: 'auto',
};

const cellSize = {
  minWidth: CELL_WIDTH,
  maxWidth: CELL_WIDTH,
  textAlign: 'center',
};

const headerText = {
  ...cellSize,
  fontSize: '12px',
  overflow: 'hidden',
};

const contentText = {
  ...cellSize,
  fontSize: '10px',
  margin: '5px 0',
  overflow: 'hidden',
  padding: '10px 0',
};

const eliminadoStyle = (eliminado) => {
  if (eliminado) {
    return {
      ...contentText,
      backgroundColor: '#f9c6c6',
    };
  }
  return {
    ...contentText,
    backgroundColor: '#bce3bc',
  };
};

const actionText = {
  ...contentText,
  textDecoration: 'underline',
  cursor: 'pointer',
};

const tableContent = {
  display: 'block',
};

const row = {
  width: '100%',
};

const inputStyle = {
  width: CELL_WIDTH,
  fontSize: '12px',
  textAlign: 'center',
};

const rowStyle = (index) => {
  if (index % 2 === 0) {
    return {
      ...row,
      backgroundColor: 'rgb(244,244,244)',
    };
  }
  return {
    ...row,
  };
};

const backStyle = {
  width: '90%',
};

const UnitTableScreen = ({
  history,
  cookies,
  getId,
}) => {
  const [usuarios, setUsuarios] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [usuario, setUsuario] = useState(0);
  const [unidad, setUnidad] = useState('');
  const [responsable, setResponsable] = useState('');
  const [chip, setChip] = useState('');
  const [imei, setImei] = useState('');
  const [gpsModel, setGpsModel] = useState('');
  const [msg, setMsg] = useState('');

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
    axios
      .get('/usuarios/all')
      .then((res) => {
        setUsuarios(res.data
          .sort((a, b) => a.usuario.localeCompare(b.usuario)));
      })
      .catch((e) => {
        setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (usuarios.length) {
      axios
        .get('/unidades')
        .then((resu) => setUnidades(resu.data
          .sort((a, b) => {
            if (a.idUsuario === b.idUsuario) {
              return a.unidad.localeCompare(b.unidad);
            }
            const usuarioa = usuarios.find((u) => u.idUsuario === a.idUsuario).usuario;
            const usuariob = usuarios.find((u) => u.idUsuario === b.idUsuario).usuario;
            return usuarioa.localeCompare(usuariob);
          })
          .map((u) => ({ ...u, editable: false }))))
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuvo más tarde.');
          console.error(e);
        });
    }
  }, [usuarios]);

  const validateUnit = (unit) => {
    if (unit.unidad.length <= 0) {
      setMsg('La unidad es un campo obligatorio.');
      return false;
    }
    if (unit.idUsuario <= 0) {
      setMsg('El usuario debe ser mayor a 0.');
      return false;
    }
    if (unit.responsable.length < 0) {
      setMsg('El responsable es un campo obligatorio');
    }
    if (`${unit.chip}`.length > 0 && (`${unit.chip}`.length < 7 || `${unit.chip}`.length > 10)) {
      setMsg('El chip debe ser un número de 7 a 10 dígitos.');
      return false;
    }
    if (`${unit.imei}`.length !== 15) {
      setMsg('El imei debe ser un número de 15 dígitos.');
      return false;
    }
    if (unit.gpsModel !== 'tk103' && unit.gpsModel !== 'tk102' && unit.gpsModel !== 'concox' && unit.gpsModel !== 'concox3g' && unit.gpsModel !== 'qbit') {
      setMsg('El modelo de gps no es valido.');
      return false;
    }
    return true;
  };

  const register = async () => {
    if (validateUnit({
      idUsuario: parseInt(usuario, 10),
      unidad,
      responsable,
      chip,
      imei,
      gpsModel,
    })) {
      const newUnit = {
        idUsuario: parseInt(usuario, 10),
        unidad,
        responsable,
        chip,
        imei,
        gpsModel,
        combustible: 12,
        eliminado: false,
        alarmas: true,
      };

      axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
      const res = await axios
        .post('/unidades', newUnit)
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
          console.error(e);
        });

      if (res.status === 200) {
        if (res.data.success) {
          const newUnidades = [...unidades];
          newUnidades.push({ ...res.data.mod, editable: false });
          setUnidades(newUnidades
            .sort((a, b) => {
              if (a.idUsuario === b.idUsuario) {
                return a.unidad.localeCompare(b.unidad);
              }
              const usuarioa = usuarios.find((u) => u.idUsuario === a.idUsuario).usuario;
              const usuariob = usuarios.find((u) => u.idUsuario === b.idUsuario).usuario;
              return usuarioa.localeCompare(usuariob);
            }));
          setMsg('Registrado con éxito.');
          setUsuario('');
          setUnidad('');
          setResponsable('');
          setChip('');
          setImei('');
          setGpsModel('');
        } else {
          setMsg(res.data.message);
        }
      }
    }
  };

  const goBack = () => history.goBack();

  const editar = async (index) => {
    const newUnidades = [...unidades];
    if (!newUnidades[index].editable) {
      newUnidades[index].editable = true;
      setUnidades(newUnidades);
    } else {
      const modUnit = {
        idUsuario: newUnidades[index].idUsuario,
        unidad: newUnidades[index].unidad,
        responsable: newUnidades[index].responsable,
        eliminado: newUnidades[index].eliminado,
        imei: newUnidades[index].imei,
        chip: newUnidades[index].chip,
        gpsModel: newUnidades[index].gpsModel,
        alarmas: newUnidades[index].alarmas,
        fechad: newUnidades[index].fechad,
        hora: newUnidades[index].hora,
        lastLat: newUnidades[index].lastLat,
        lastLng: newUnidades[index].lastLng,
        grupos: newUnidades[index].grupos,
      };
      axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
      const res = await axios
        .patch(`/unidades/${newUnidades[index].idUnidad}`, modUnit)
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
          console.error(e);
        });
      if (res.status && res.status === 200) {
        if (res.data.count.length === 1) {
          setMsg('Modificado con éxito.');
          newUnidades[index].editable = false;
          setUnidades(newUnidades);
        } else {
          setMsg('Hubo un error, intenta nuevamente');
        }
      }
    }
  };

  const getUser = (id) => {
    const us = usuarios.find((u) => u.idUsuario === id);
    if (typeof us !== 'undefined') {
      return us.usuario;
    }
    return '-';
  };

  const handleChange = (e, index, property) => {
    const newUnidades = [...unidades];
    const newUnit = unidades[index];
    newUnit[property] = e.target.value;
    newUnidades[index] = newUnit;
    setUnidades(newUnidades);
  };

  const handleCheckbox = (e, index, property) => {
    const newUnidades = [...unidades];
    const newUnit = unidades[index];
    newUnit[property] = e.target.checked;
    newUnidades[index] = newUnit;
    setUnidades(newUnidades);
  };

  if (parseInt(getId(), 10) !== 1) return <Redirect to="/" />;

  return (
    <div style={container}>
      <AppBar position="static">
        <Toolbar>
          <FA
            className="menu-btn"
            name="chevron-left"
            onClick={goBack}
          />
          <Typography style={backStyle} variant="h6" color="inherit">
            CAEBES
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={phase1}>
        <h2>Unidades</h2>
        <table style={table}>
          <thead>
            <tr>
              <th style={headerText}>
                idUnidad
              </th>
              <th style={headerText}>
                idUsuario
              </th>
              <th style={headerText}>
                Usuario
              </th>
              <th style={headerText}>
                Editar/Guardar
              </th>
              <th style={headerText}>
                unidad
              </th>
              <th style={headerText}>
                responsable
              </th>
              <th style={headerText}>
                eliminado
              </th>
              <th style={headerText}>
                imei
              </th>
              <th style={headerText}>
                chip
              </th>
              <th style={headerText}>
                gpsModel
              </th>
              <th style={headerText}>
                alarmas
              </th>
              <th style={headerText}>
                fechad
              </th>
              <th style={headerText}>
                hora
              </th>
              <th style={headerText}>
                lastLat
              </th>
              <th style={headerText}>
                lastLng
              </th>
              <th style={headerText}>
                grupos
              </th>
            </tr>
          </thead>
          <tbody style={tableContent}>
            <tr style={rowStyle(-1)}>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="text" name="usuario" id="usuario-new" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={actionText} onClick={register}>
                Guardar
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="text" name="unidad" id="unidad-new" value={unidad} onChange={(e) => setUnidad(e.target.value)} placeholder="nueva unidad" />
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="text" name="responsable" id="responsable-new" value={responsable} onChange={(e) => setResponsable(e.target.value)} />
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="number" name="imei" id="imei-new" value={imei} onChange={(e) => setImei(e.target.value)} />
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="text" name="chip" id="chip-new" value={chip} onChange={(e) => setChip(e.target.value)} />
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="text" name="gpsModel" id="gpsModel-new" value={gpsModel} onChange={(e) => setGpsModel(e.target.value)} />
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -
              </td>
            </tr>
            {unidades.map((unit, index) => {
              if (!unit.editable) {
                return (
                  <tr style={rowStyle(index)} key={unit.idUnidad}>
                    <td style={contentText}>
                      {unit.idUnidad}
                    </td>
                    <td style={contentText}>
                      {unit.idUsuario}
                    </td>
                    <td style={contentText}>
                      {getUser(unit.idUsuario)}
                    </td>
                    <td style={actionText} onClick={() => editar(index)}>
                      {unit.editable ? 'Guardar' : 'Editar'}
                    </td>
                    <td style={contentText}>
                      {unit.unidad}
                    </td>
                    <td style={contentText}>
                      {unit.responsable}
                    </td>
                    <td style={eliminadoStyle(unit.eliminado)}>
                      {unit.eliminado ? 'eliminado' : 'activo'}
                    </td>
                    <td style={contentText}>
                      {unit.imei}
                    </td>
                    <td style={contentText}>
                      <a href={`tel:+52${unit.chip}`}>
                        {unit.chip}
                      </a>
                    </td>
                    <td style={contentText}>
                      {unit.gpsModel}
                    </td>
                    <td style={eliminadoStyle(!unit.alarmas)}>
                      {unit.alarmas ? 'activadas' : 'desactivadas'}
                    </td>
                    <td style={contentText}>
                      {unit.fechad}
                    </td>
                    <td style={contentText}>
                      {unit.hora}
                    </td>
                    <td style={contentText}>
                      {unit.lastLat}
                    </td>
                    <td style={contentText}>
                      {unit.lastLng}
                    </td>
                    <td style={contentText}>
                      {unit.grupos}
                    </td>
                  </tr>
                );
              }
              // is editable
              return (
                <tr style={rowStyle(index)} key={unit.idUnidad}>
                  <td style={contentText}>
                    {unit.idUnidad}
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="idUsuario" id={`idusuario-${unit.idUsuario}`} value={unit.idUsuario} onChange={(e) => handleChange(e, index, 'idUsuario')} />
                  </td>
                  <td style={contentText}>
                    {getUser(unit.idUsuario || 0)}
                  </td>
                  <td style={actionText} onClick={() => editar(index)}>
                    {unit.editable ? 'Guardar' : 'Editar'}
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="text" name="unidad" id={`unidad-${unit.idUnidad}`} value={unit.unidad} onChange={(e) => handleChange(e, index, 'unidad')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="text" name="responsable" id={`responsable-${unit.idUnidad}`} value={unit.responsable} onChange={(e) => handleChange(e, index, 'responsable')} />
                  </td>
                  <td style={eliminadoStyle(unit.eliminado)}>
                    <input type="checkbox" name="eliminado" id={`eliminado-${unit.idUnidad}`} checked={unit.eliminado} onChange={(e) => handleCheckbox(e, index, 'eliminado')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="imei" id={`imei-${unit.idUnidad}`} value={unit.imei} onChange={(e) => handleChange(e, index, 'imei')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="chip" id={`chip-${unit.idUnidad}`} value={unit.chip} onChange={(e) => handleChange(e, index, 'chip')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="text" name="gpsModel" id={`gpsModel-${unit.idUnidad}`} value={unit.gpsModel} onChange={(e) => handleChange(e, index, 'gpsModel')} />
                  </td>
                  <td style={eliminadoStyle(!unit.alarmas)}>
                    <input type="checkbox" name="alarmas" id={`alarmas-${unit.idUnidad}`} checked={unit.alarmas} onChange={(e) => handleCheckbox(e, index, 'alarmas')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="date" name="fechad" id={`fechad-${unit.idUnidad}`} value={unit.fechad} onChange={(e) => handleChange(e, index, 'fechad')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="time" name="hora" id={`hora-${unit.idUnidad}`} value={unit.hora} onChange={(e) => handleChange(e, index, 'hora')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="lastLat" id={`lastLat-${unit.idUnidad}`} value={unit.lastLat} onChange={(e) => handleChange(e, index, 'lastLat')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="lastLng" id={`lastLng-${unit.idUnidad}`} value={unit.lastLng} onChange={(e) => handleChange(e, index, 'lastLng')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="text" name="grupos" id={`grupos-${unit.idUnidad}`} value={unit.grupos} onChange={(e) => handleChange(e, index, 'grupos')} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={msg !== ''}
        autoHideDuration={6000}
        onClose={() => setMsg('')}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
      />
    </div>
  );
};

export default UnitTableScreen;
