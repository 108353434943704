import React, { useState } from 'react';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Polyline,
  Marker,
  InfoWindow,
} from 'react-google-maps';

import marker from '../Map/marker-small.png';
import markerOff from '../Map/marker-small-off.png';

const segmentPath = (path) => {
  if (path.length > 0) {
    const segmentedPaths = [];
    for (let i = 1; i < path.length; i += 1) {
      segmentedPaths.push([path[i - 1], path[i]]);
    }
    return segmentedPaths;
  }
  return path;
};

const MapRuta = withScriptjs(
  withGoogleMap((props) => {
    const [positionMarker, setPositionMarker] = useState({
      lat: 0,
      lng: 0,
      isVisible: false,
    });
    return (
      <GoogleMap
        ref={props.onMapMounted}
        onBoundsChanged={props.onBoundsChanged}
        defaultZoom={11}
        defaultCenter={{
          lat: 19.702864,
          lng: -101.192345,
        }}
        onClick={() => {
          setPositionMarker({ lat: 0, lng: 0, isVisible: false });
        }}
      >
        <div>
          {segmentPath(props.path).map((path) => (
            <Polyline
              key={`${path[0].id}-${path[1].id}`}
              path={path}
              visible
              options={{ strokeColor: 'midnightblue' }}
              onClick={(e) => {
                const latDiff = e.latLng.lat() - path[0].lat;
                const lnfDiff = e.latLng.lng() - path[0].lng;
                const distance = Math.sqrt((latDiff ** 2) + (lnfDiff ** 2));
                const latDiff2 = e.latLng.lat() - path[1].lat;
                const lnfDiff2 = e.latLng.lng() - path[1].lng;
                const distance2 = Math.sqrt((latDiff2 ** 2) + (lnfDiff2 ** 2));
                setPositionMarker({ ...path[(distance < distance2 ? 0 : 1)], isVisible: true });
              }}
            />
          ))}
          <Marker
            position={props.path[0]}
            onClick={() => {}}
            icon={{ url: markerOff, labelOrigin: { x: 23, y: 31 } }}
            label={{ text: 'inicio', color: '#fff', fontWeight: 'bold' }}
          />
          {props.path.length > 1
          && (
            <Marker
              position={props.path[props.path.length - 1]}
              onClick={() => {}}
              icon={{ url: marker, labelOrigin: { x: 23, y: 31 } }}
              label={{ text: 'fin', color: '#fff', fontWeight: 'bold' }}
            />
          )}
          {positionMarker.isVisible
          && (
            <Marker
              position={positionMarker}
              onClick={() => {
                setPositionMarker(({ lat: 0, lng: 0, isVisible: false }));
              }}
              visible={false}
            >
              <InfoWindow
                onCloseClick={() => {
                  setPositionMarker(({ lat: 0, lng: 0, isVisible: false }));
                }}
              >
                <table>
                  <tr>
                    <td>Hora:</td>
                    <td>{positionMarker.hora}</td>
                  </tr>
                  <tr>
                    <td>Velocidad:</td>
                    <td>{`${Math.round(positionMarker.velocidad)} km/h`}</td>
                  </tr>
                </table>
              </InfoWindow>
            </Marker>
          )}
        </div>
      </GoogleMap>
    );
  }),
);

export default MapRuta;
