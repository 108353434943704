import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SnackBar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const classes = (theme) => ({
  close: {
    position: 'absolute',
    right: 12,
    top: 'auto',
    padding: theme.spacing.unit / 2,
  },
});

const Snackbar = ({
  open,
  close,
  children,
}) => (
  <SnackBar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={open}
    autoHideDuration={6000}
    onClose={close}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{children}</span>}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={classes.close}
        onClick={close}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

Snackbar.defaultProps = {
};

export default withStyles(classes)(Snackbar);
