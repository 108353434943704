import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import SearchIcon from '../Map/searchIcon.svg';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  searchButton: {
    marginLeft: 0,
    marginRight: 0,
  },
  colorWhite: {
    color: 'white',
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
  tbButton: {
    marginRight: 210,
  },
};

const NavBar = ({
  active,
  toggleMenu,
  classes,
  getId,
  getOwnerId,
  showFilterBar,
  setShowFilterBar,
}) => {
  const [showList, setShowList] = useState(false);
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar id="menucontainer">
          <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={toggleMenu}>
            {!active
              && <MenuIcon />}
            {active
              && <CloseIcon />}
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            CAEBES
          </Typography>
          {(parseInt(getId(), 10) === 1 && (parseInt(getOwnerId(), 10) === 1))
            && (
              <IconButton className={showFilterBar ? classes.tbButton : {}} color="inherit" aria-label="Register" onClick={() => setShowList(true)}>
                <HowToRegIcon className={classes.colorWhite} />
              </IconButton>
            )}
          <IconButton id="filterbar-button" color="inherit" aria-label="Search" onClick={() => setShowFilterBar(true)}>
            <img src={SearchIcon} alt="icono de buscar" />
          </IconButton>
          <Popover
            id="simple-popper"
            open={showList}
            onClose={() => setShowList(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List component="nav">
              <ListItem button>
                <Link to="/userRegister" className={classes.colorWhite}>
                  <ListItemText primary="Admin Usuarios" />
                </Link>
              </ListItem>
              <ListItem button>
                <Link to="/unitTable" className={classes.colorWhite}>
                  <ListItemText primary="Admin Unidades" />
                </Link>
              </ListItem>
              <ListItem button>
                <Link to="/usrunitTable" className={classes.colorWhite}>
                  <ListItemText primary="Asignar Unidades" />
                </Link>
              </ListItem>
              <ListItem button>
                <Link to="/user/zoneReport" className={classes.colorWhite}>
                  <ListItemText primary="Reporte de Zonas" />
                </Link>
              </ListItem>
            </List>
          </Popover>
        </Toolbar>
      </AppBar>
    </div>
  );
};

NavBar.propTypes = {
  active: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dense: PropTypes.string,
    gutters: PropTypes.string,
    regular: PropTypes.string,
    root: PropTypes.string,
  }),
};

NavBar.defaultProps = {
  classes: null,
};

export default withStyles(styles)(NavBar);
