/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

class ModalRuta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unidad: '',
      ok: false,
    };
  }

  handleChange = (event) => {
    this.setState({ unidad: event.target.value });
  };

  consultarRuta = () => {
    const { setUnidadRuta, unidades } = this.props;
    const { unidad } = this.state;
    if (unidad !== '') {
      const unidadObj = unidades.find((unit) => unit.idUnidad === parseInt(unidad, 10));
      setUnidadRuta(unidadObj);
      this.setState({ ok: true });
    }
  };

  render() {
    const {
      visible,
      close,
      unidades,
      classes,
    } = this.props;
    const { ok, unidad } = this.state;
    const options = unidades
      .map((unidadOp) => (
        <option value={unidadOp.idUnidad} key={unidadOp.idUnidad}>{unidadOp.unidad}</option>
      ));
    if (ok) return <Redirect to="/ruta" />;
    return (visible
      && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={visible}
          onClose={close}
        >
          <DialogTitle>Selecciona la unidad</DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="unit-input">nombre unidad</InputLabel>
                <Select
                  native
                  value={unidad}
                  onChange={this.handleChange}
                  input={<Input id="unit-input" />}
                >
                  <option value="" />
                  {options}
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.consultarRuta} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  }
}

ModalRuta.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  unidades: PropTypes.arrayOf(
    PropTypes.shape({
      idUnidad: PropTypes.number,
      idUsuario: PropTypes.number,
      unidad: PropTypes.string,
      responsable: PropTypes.string,
      combustible: PropTypes.number,
      eliminado: PropTypes.bool,
      imei: PropTypes.string,
      idGremio: PropTypes.number,
      idGeocerca: PropTypes.number,
      chip: PropTypes.string,
      motor: PropTypes.bool,
      lastOffLat: PropTypes.number,
      lastOffLng: PropTypes.number,
      autoTimeZone: PropTypes.bool,
      insideGeoFence: PropTypes.bool,
      gpsModel: PropTypes.string,
    }),
  ).isRequired,
  setUnidadRuta: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dense: PropTypes.string,
    gutters: PropTypes.string,
    regular: PropTypes.string,
    root: PropTypes.string,
  }),
};

ModalRuta.defaultProps = {
  classes: null,
};

export default withStyles(styles)(ModalRuta);
