import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as Axios from 'axios';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FA from 'react-fontawesome';

import SECRETS from '../secrets';

const axios = Axios.create({
  baseURL: SECRETS.SERVERURL,
});

const container = {
  height: '100vh',
  width: '100%',
  backgroundColor: '#fff',
  color: '#848484',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const phase1 = {
  height: '90%',
  width: '100%',
  padding: 10,
  alignItems: 'center',
};

const selectStyle = {
  borderBottom: '1 solid #454545',
  height: '50px',
  width: '80%',
  margin: 10,
  marginLeft: '10%',
};

const listStyle = {
  height: '70%',
  overflow: 'auto',
  margin: 10,
};

const header = {
  fontSize: '30px',
  fontWeight: 'bold',
  alignSelf: 'center',
  textAlign: 'center',
  paddingTop: '20px',
  color: '#454545',
};

const backStyle = {
  width: '90%',
};

const loadSubUsers = (userId, setSubUsuarios, setMsg) => {
  axios
    .get(`/usuarios/subUsers/${userId}`)
    .then((res) => {
      res.data.sort((a, b) => a.usuario.localeCompare(b.usuario));
      setSubUsuarios(res.data);
    })
    .catch((e) => {
      setMsg('Error al listar los subusuarios.');
      console.error(e);
    });
};

const EditSubUsers = ({
  history,
  cookies,
  getId,
  getOwnerId,
}) => {
  const [superUsuario, setSuperUsuario] = useState(0);
  const [usuarios, setUsuarios] = useState([]);
  const [subUsuarios, setSubUsuarios] = useState([]);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
    axios
      .get('/usuarios/superUsers/all')
      .then((res) => {
        res.data.sort((a, b) => a.usuario.localeCompare(b.usuario));
        setUsuarios(res.data);
        setSuperUsuario(parseInt(getId(), 10), setSubUsuarios, setMsg);
      })
      .catch((e) => {
        setMsg('Error al listar los usuarios.');
        console.error(e);
      });

    loadSubUsers(getId());
  });

  if (getId() < 1 || getOwnerId() > 1) return <Redirect to="/" />;

  const usuarioChange = (event) => {
    const idUsuario = event.target.value;
    setSuperUsuario(idUsuario);
    loadSubUsers(idUsuario);
  };

  const goBack = () => history.goBack();

  const handleCheck = (user) => () => {
    axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
    axios
      .patch(`/usuarios/${user.idUsuario}`, {
        eliminado: !user.eliminado,
      })
      .then(() => {
        loadSubUsers(superUsuario);
        setMsg(user.eliminado ? 'Usuario habilitado' : 'Usuario deshabilitado');
      })
      .catch((e) => {
        setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
        console.error(e);
      });
  };

  return (
    <div style={container}>
      <AppBar position="static">
        <Toolbar>
          <FA
            className="menu-btn"
            name="chevron-left"
            onClick={goBack}
          />
          <Typography style={backStyle} variant="h6" color="inherit">
            CAEBES
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={phase1}>
        <div style={header}>Editar Subusuarios</div>
        <Select
          value={superUsuario}
          onChange={usuarioChange}
          displayEmpty
          style={selectStyle}
          disabled={parseInt(getId(), 10) > 1}
        >
          {usuarios.map((user) => <MenuItem key={`${user.idUsuario}-${user.usuario}`} value={user.idUsuario}>{user.usuario}</MenuItem>)}
        </Select>
        <List style={listStyle}>
          {subUsuarios.length === 0 && (
            <Typography variant="h6" color="inherit">
              No tiene sub usuarios
            </Typography>
          )}
          {subUsuarios.map((user) => (
            <ListItem key={user.idUsuario} dense button onClick={handleCheck(user)}>
              <Checkbox
                checked={!user.eliminado}
              />
              <ListItemText primary={user.usuario} />
            </ListItem>
          ))}
        </List>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={msg !== ''}
        autoHideDuration={6000}
        onClose={() => setMsg('')}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
      />
    </div>
  );
};

export default EditSubUsers;
