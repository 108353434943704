import React from 'react';
import PropTypes from 'prop-types';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import FA from 'react-fontawesome';

import Modal from '../Components/Modal';
import Promt from '../Components/Promt';

const ModalApagar = ({
  visible,
  close,
  unidades,
  confirmPromt,
  promtVisible,
  closePromt,
  apagarAuto,
}) => (
  <TransitionGroup>
    {visible && (
      <CSSTransition
        in={visible}
        key="modal01"
        classNames="modal"
        timeout={{ enter: 500, exit: 300 }}
      >
        <Modal className="modal" close={close}>
          <h3>
            Selecciona la unidad a
            {' '}
            <span className="darkred">apagar</span>
          </h3>
          <TransitionGroup>
            {unidades.map((unidad) => (
              <CSSTransition key={unidad.idUnidad} classNames="modal-item" timeout={800}>
                <div
                  key={unidad.idUnidad}
                  className="modal-item"
                  onClick={() => confirmPromt(unidad.chip)}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  <FA name="car" size="2x" className="darkred" />
                  <span>
                    Unidad:
                    {' '}
                    {unidad.unidad}
                  </span>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Modal>
      </CSSTransition>
    )}
    {promtVisible && (
      <CSSTransition key="promt-apagar01" classNames="promt" timeout={300}>
        <Promt className="promt" close={closePromt} confirm={apagarAuto}>
          <h3>
            ¿Desea
            {' '}
            <span className="darkred">apagar</span>
            {' '}
            la unidad?
          </h3>
        </Promt>
      </CSSTransition>
    )}
  </TransitionGroup>
);

ModalApagar.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  unidades: PropTypes.arrayOf(
    PropTypes.shape({
      idUnidad: PropTypes.number,
      chip: PropTypes.string,
      unidad: PropTypes.string,
    }),
  ).isRequired,
  confirmPromt: PropTypes.func.isRequired,
  promtVisible: PropTypes.bool.isRequired,
  closePromt: PropTypes.func.isRequired,
  apagarAuto: PropTypes.func.isRequired,
};

export default ModalApagar;
