import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import Moment from 'moment';

const styles = (theme) => ({
  button: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    borderRadius: 0,
  },
  subheader: {
    textTransform: 'capitalize',
  },
  caption: {
    marginTop: 20,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
});

const FeaturesPanel = ({ fuelPrices, classes }) => {
  const [panelOpened, setPanelOpened] = useState(false);

  const togglePanel = (open) => setPanelOpened(open);

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={() => togglePanel(true)}
      >
        <ExpandLessIcon />
      </Button>
      <Drawer
        anchor="bottom"
        onClose={() => togglePanel(false)}
        open={panelOpened}
      >
        <Card>
          <CardHeader
            avatar={<Avatar aria-label="Combustible" className={classes.avatar}><LocalGasStationIcon /></Avatar>}
            action={(
              <IconButton
                onClick={() => togglePanel(false)}
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
            title="Precios de combustible"
            subheader={<span className={classes.subheader}>{Moment().format('MMMM DD, YYYY')}</span>}
          />
          <CardContent>
            <Typography component="p" gutterBottom>
              <strong>Gasolina Regular: </strong>
              {`$${Math.round(fuelPrices.magna * 100) / 100}`}
              <br />
              <strong>Gasolina Premium: </strong>
              {`$${Math.round(fuelPrices.premium * 100) / 100}`}
              <br />
              <strong>Diésel: </strong>
              {`$${Math.round(fuelPrices.diesel * 100) / 100}`}
            </Typography>
            <Typography variant="caption" color="textSecondary" className={classes.caption}>
              Precios de gasolinas y diésel
              {' '}
              publicado por Comisión Reguladora de Energía. Consultado en
              {' '}
              <span>http://www.cre.gob.mx/</span>
              {' '}
              el
              {` ${fuelPrices.fecha}.`}
            </Typography>
          </CardContent>
        </Card>
      </Drawer>
    </>
  );
};

FeaturesPanel.propTypes = {
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  fuelPrices: PropTypes.shape({
    diesel: PropTypes.number,
    estado: PropTypes.number,
    fecha: PropTypes.string,
    id: PropTypes.number,
    magna: PropTypes.number,
    municipio: PropTypes.number,
    premium: PropTypes.number,
  }),
};

FeaturesPanel.defaultProps = {
  classes: {},
  fuelPrices: {},
};

export default withStyles(styles)(FeaturesPanel);
