/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as Axios from 'axios';
import generator from 'generate-password';
import Snackbar from '@material-ui/core/Snackbar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FA from 'react-fontawesome';

import SECRETS from '../secrets';

const axios = Axios.create({
  baseURL: SECRETS.SERVERURL,
});

const EMAILREGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const CELL_WIDTH = '150px';

const container = {
  height: '100vh',
  width: '100vw',
  backgroundColor: '#fff',
  color: '#848484',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const phase1 = {
  width: '100%',
  height: '100%',
};

const table = {
  display: 'block',
  width: '99vw',
  height: '85vh',
  overflow: 'scroll',
  scrollbarColor: '#ccc #333',
  scrollbarWidth: 'auto',
};

const cellSize = {
  minWidth: CELL_WIDTH,
  maxWidth: CELL_WIDTH,
  textAlign: 'center',
};

const headerText = {
  ...cellSize,
  fontSize: '12px',
  overflow: 'hidden',
};

const contentText = {
  ...cellSize,
  fontSize: '10px',
  margin: '5px 0',
  overflow: 'hidden',
  padding: '10px 0',
};

const eliminadoStyle = (eliminado) => {
  if (eliminado) {
    return {
      ...contentText,
      backgroundColor: '#f9c6c6',
    };
  }
  return {
    ...contentText,
    backgroundColor: '#bce3bc',
  };
};

const actionText = {
  ...contentText,
  textDecoration: 'underline',
  cursor: 'pointer',
};

const tableContent = {
  display: 'block',
};

const row = {
  width: '100%',
};

const inputStyle = {
  width: CELL_WIDTH,
  fontSize: '12px',
  textAlign: 'center',
};

const rowStyle = (index) => {
  if (index % 2 === 0) {
    return {
      ...row,
      backgroundColor: 'rgb(244,244,244)',
    };
  }
  return {
    ...row,
  };
};

const backStyle = {
  width: '90%',
};

const UserTableScreen = ({
  history,
  cookies,
  getId,
}) => {
  const [usuarios, setUsuarios] = useState([]);
  const [nombre, setNombre] = useState('');
  const [usuario, setUsuario] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [msg, setMsg] = useState('');

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
    axios
      .get('/usuarios/all')
      .then((res) => setUsuarios(res.data
        .sort((a, b) => a.usuario.localeCompare(b.usuario))
        .map((u) => ({ ...u, editable: false, togglePass: false }))))
      .catch((e) => {
        setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
        console.error(e);
      });
  }, []);

  const validateUser = (user) => {
    if (user.nombre.length <= 0) {
      setMsg('El nombre es un campo obligatorio.');
      return false;
    }
    if (user.usuario.length <= 0) {
      setMsg('El usuario es un campo obligatorio.');
      return false;
    }
    if (user.correo.length > 0 && !EMAILREGEX.test(correo)) {
      setMsg('Ingresa un correo válido.');
      return false;
    }
    if (`${user.telefono}`.length > 0 && (`${user.telefono}`.length < 7 || `${user.telefono}`.length > 10)) {
      setMsg('El telefono debe ser un número de 7 a 10 dígitos.');
      return false;
    }

    return true;
  };

  const copyPass = async (pass) => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(pass);
      } catch (err) {
        console.error('Error al copiar!', err);
        setMsg('Error al copiar.');
      }
    } else {
      console.error('No se tiene acceso al clipboard del navegador.');
    }
  };

  const register = async () => {
    if (validateUser({
      nombre,
      usuario,
      telefono,
      correo,
    })) {
      const pass = generator.generate({
        length: 12,
        numbers: true,
        symbols: true,
        strict: true,
      });

      const newUser = {
        nombre,
        pass,
        usuario,
        telefono,
        correo,
        municipio: 53, // Morelia
        estado: 16, // Michoacán
        eliminado: 0,
        ownerId: getId(),
      };

      axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
      const res = await axios
        .post('/usuarios', newUser)
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
          console.error(e);
        });

      if (res.status === 200) {
        if (res.data.success) {
          const newUsuarios = [...usuarios];
          newUsuarios.push({ ...res.data.mod, editable: false, togglePass: false });
          setUsuarios(newUsuarios.sort((a, b) => a.usuario.localeCompare(b.usuario)));
          setMsg('Registrado con éxito.');
          copyPass(pass);
          setNombre('');
          setUsuario('');
          setTelefono('');
          setCorreo('');
        } else {
          setMsg(res.data.message);
        }
      }
    }
  };

  const goBack = () => history.goBack();

  const editar = async (index) => {
    const newUsuarios = [...usuarios];
    if (!newUsuarios[index].editable) {
      newUsuarios[index].editable = true;
      setUsuarios(newUsuarios);
    } else {
      axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
      const res = await axios
        .patch(`/usuarios/${newUsuarios[index].idUsuario}`, newUsuarios[index])
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
          console.error(e);
        });

      if (res.status === 200) {
        if (res.data.count.length === 1) {
          setMsg('Modificado con éxito.');
          newUsuarios[index].editable = false;
          setUsuarios(newUsuarios);
        } else {
          setMsg('Hubo un error, intenta nuevamente');
        }
      }
    }
  };

  const handleChange = (e, index, property) => {
    const newUsuarios = [...usuarios];
    const newUser = usuarios[index];
    newUser[property] = e.target.value;
    newUsuarios[index] = newUser;
    setUsuarios(newUsuarios);
  };

  const handleCheckbox = (e, index, property) => {
    const newUsuarios = [...usuarios];
    const newUser = usuarios[index];
    newUser[property] = e.target.checked;
    newUsuarios[index] = newUser;
    setUsuarios(newUsuarios);
  };

  const newPassword = async (index) => {
    if (!usuarios[index].togglePass) {
      const newUsuarios = [...usuarios];
      newUsuarios[index].togglePass = true;
      setUsuarios(newUsuarios);
    } else {
      const pass = generator.generate({
        length: 12,
        numbers: true,
        symbols: true,
        strict: true,
      });
      const newUser = { ...usuarios[index], pass };
      axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
      const res = await axios
        .patch(`/usuarios/${usuarios[index].idUsuario}`, newUser)
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
          console.error(e);
        });

      if (res.status === 200) {
        if (res.data.count.length === 1) {
          copyPass(pass);
          const newUsuarios = [...usuarios];
          newUsuarios[index].togglePass = false;
          setUsuarios(newUsuarios);
          setMsg('Contraseña modificada y copiada.');
        } else {
          setMsg('Hubo un error, intenta nuevamente');
        }
      }
    }
  };

  if (parseInt(getId(), 10) !== 1) return <Redirect to="/" />;

  return (
    <div style={container}>
      <AppBar position="static">
        <Toolbar>
          <FA
            className="menu-btn"
            name="chevron-left"
            onClick={goBack}
          />
          <Typography style={backStyle} variant="h6" color="inherit">
            CAEBES
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={phase1}>
        <h2>Usuarios</h2>
        <table style={table}>
          <thead>
            <tr>
              <th style={headerText}>
                idUsuario
              </th>
              <th style={headerText}>
                Editar/Guardar
              </th>
              <th style={headerText}>
                nombre
              </th>
              <th style={headerText}>
                usuario
              </th>
              <th style={headerText}>
                password
              </th>
              <th style={headerText}>
                telefono
              </th>
              <th style={headerText}>
                correo
              </th>
              <th style={headerText}>
                eliminado
              </th>
              <th style={headerText}>
                reportFrecuency
              </th>
              <th style={headerText}>
                alarmNotifications
              </th>
              <th style={headerText}>
                reportDate
              </th>
              <th style={headerText}>
                estado
              </th>
              <th style={headerText}>
                municipio
              </th>
              <th style={headerText}>
                privacyTerms
              </th>
              <th style={headerText}>
                diaCorte
              </th>
              <th style={headerText}>
                fechaAviso
              </th>
              <th style={headerText}>
                periodoMeses
              </th>
              <th style={headerText}>
                ownerId
              </th>
              <th style={headerText}>
                permissionLevel
              </th>
            </tr>
          </thead>
          <tbody style={tableContent}>
            <tr style={rowStyle(-1)}>
              <td style={contentText}>
                -
              </td>
              <td style={actionText} onClick={register}>
                Guardar
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="text" name="nombre" id="nombre-new" value={nombre} onChange={(e) => setNombre(e.target.value)} placeholder="nuevo usuario" />
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="text" name="usuario" id="usuario-new" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="number" name="telefono" id="telefono-new" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="text" name="correo" id="correo-new" value={correo} onChange={(e) => setCorreo(e.target.value)} />
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -1
              </td>
              <td style={contentText}>
                3
              </td>
              <td style={contentText}>
                1
              </td>
              <td style={contentText}>
                16
              </td>
              <td style={contentText}>
                53
              </td>
              <td style={contentText}>
                false
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -
              </td>
            </tr>
            {usuarios.map((user, index) => {
              if (!user.editable) {
                return (
                  <tr style={rowStyle(index)} key={user.idUsuario}>
                    <td style={contentText}>
                      {user.idUsuario}
                    </td>
                    <td style={actionText} onClick={() => editar(index)}>
                      {user.editable ? 'Guardar' : 'Editar'}
                    </td>
                    <td style={contentText}>
                      {user.nombre}
                    </td>
                    <td style={contentText}>
                      {user.usuario}
                    </td>
                    <td style={actionText} onClick={() => newPassword(index)}>
                      {user.togglePass ? 'confirmar' : 'reset password'}
                    </td>
                    <td style={contentText}>
                      {user.telefono}
                    </td>
                    <td style={contentText}>
                      {user.correo}
                    </td>
                    <td style={eliminadoStyle(user.eliminado)}>
                      {user.eliminado ? 'eliminado' : 'activo'}
                    </td>
                    <td style={contentText}>
                      {user.reportFrecuency}
                    </td>
                    <td style={contentText}>
                      {user.alarmNotifications}
                    </td>
                    <td style={contentText}>
                      {user.reportDate}
                    </td>
                    <td style={contentText}>
                      {user.estado}
                    </td>
                    <td style={contentText}>
                      {user.municipio}
                    </td>
                    <td style={contentText}>
                      {user.privacyTerms ? 'true' : 'false'}
                    </td>
                    <td style={contentText}>
                      {user.diaCorte}
                    </td>
                    <td style={contentText}>
                      {user.fechaAviso}
                    </td>
                    <td style={contentText}>
                      {user.periodoMeses}
                    </td>
                    <td style={contentText}>
                      {user.ownerId}
                    </td>
                    <td style={contentText}>
                      {user.permissionLevel}
                    </td>
                  </tr>
                );
              }
              // is editable
              return (
                <tr style={rowStyle(index)} key={user.idUsuario}>
                  <td style={contentText}>
                    {user.idUsuario}
                  </td>
                  <td style={actionText} onClick={() => editar(index)}>
                    {user.editable ? 'Guardar' : 'Editar'}
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="text" name="nombre" id={`nombre-${user.idUsuario}`} value={user.nombre} onChange={(e) => handleChange(e, index, 'nombre')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="text" name="usuario" id={`usuario-${user.idUsuario}`} value={user.usuario} onChange={(e) => handleChange(e, index, 'usuario')} />
                  </td>
                  <td style={actionText}>
                    reset password
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="telefono" id={`telefono-${user.idUsuario}`} value={user.telefono} onChange={(e) => handleChange(e, index, 'telefono')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="text" name="correo" id={`correo-${user.idUsuario}`} value={user.correo} onChange={(e) => handleChange(e, index, 'correo')} />
                  </td>
                  <td style={eliminadoStyle(user.eliminado)}>
                    <input type="checkbox" name="eliminado" id={`eliminado-${user.idUsuario}`} checked={user.eliminado} onChange={(e) => handleCheckbox(e, index, 'eliminado')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="reportFrecuency" id={`reportFrecuency-${user.idUsuario}`} value={user.reportFrecuency} onChange={(e) => handleChange(e, index, 'reportFrecuency')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="alarmNotifications" id={`alarmNotifications-${user.idUsuario}`} value={user.alarmNotifications} onChange={(e) => handleChange(e, index, 'alarmNotifications')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="reportDate" id={`reportDate-${user.idUsuario}`} value={user.reportDate} onChange={(e) => handleChange(e, index, 'reportDate')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="estado" id={`estado-${user.idUsuario}`} value={user.estado} onChange={(e) => handleChange(e, index, 'estado')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="municipio" id={`municipio-${user.idUsuario}`} value={user.municipio} onChange={(e) => handleChange(e, index, 'municipio')} />
                  </td>
                  <td style={contentText}>
                    {user.privacyTerms ? 'true' : 'false'}
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="diaCorte" id={`diaCorte-${user.idUsuario}`} value={user.diaCorte} onChange={(e) => handleChange(e, index, 'diaCorte')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="date" name="fechaAviso" id={`fechaAviso-${user.idUsuario}`} value={user.fechaAviso} onChange={(e) => handleChange(e, index, 'fechaAviso')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="periodoMeses" id={`periodoMeses-${user.idUsuario}`} value={user.periodoMeses} onChange={(e) => handleChange(e, index, 'periodoMeses')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="ownerId" id={`ownerId-${user.idUsuario}`} value={user.ownerId} onChange={(e) => handleChange(e, index, 'ownerId')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="number" name="permissionLevel" id={`permissionLevel-${user.idUsuario}`} value={user.permissionLevel} onChange={(e) => handleChange(e, index, 'permissionLevel')} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={msg !== ''}
        autoHideDuration={6000}
        onClose={() => setMsg('')}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
      />
    </div>
  );
};

export default UserTableScreen;
