import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import DateSelector from './DateSelector';
import { MAXSPEED, WARNINGSPEED } from './options';

const styles = {
  card: {
    minWidth: 275,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.9em',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  total: {
    color: 'white',
  },
  totalWarn: {
    fontWeight: 'bold',
    color: 'white',
  },
  noContentCard: {
    minHeight: 73,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const RutaOptions = ({
  data,
  getData,
  setRuta,
  unidad,
  isParkedNotNull,
  setRutaAll,
  classes,
  loadingData,
  currentDayLoaded,
  selectorDisabled,
  date,
}) => {
  const generateParkedStateContent = (log) => (log.isParked === null || !log.isParked ? 'movimiento' : 'detenido');

  const generateParkedStateClassName = (log) => {
    if ((log.isParked !== null && !log.isParked)) {
      return 'green bold';
    }
    return 'orange bold';
  };

  const genTimeString = (timeinseconds) => {
    if (typeof timeinseconds === 'undefined') {
      return '0h 0m 0s';
    }
    const hours = timeinseconds / 60 / 60;
    const mins = (hours - Math.floor(hours)) * 60;
    const secs = (mins - Math.floor(mins)) * 60;
    return `${Math.floor(hours)}h ${Math.floor(mins)}m ${Math.round(secs)}s`;
  };

  const getPercentage = (value, total) => {
    if (typeof value === 'undefined' || typeof total === 'undefined') {
      return 0;
    }
    return Math.round((value / total) * 100);
  };

  let distanciaTotal = 0;
  let velocidadMax = 0;
  let tiempoMotor = 0;
  data.forEach((d) => {
    if ((isParkedNotNull && !d.isParked) || d.speedMax > MAXSPEED) {
      velocidadMax = velocidadMax < d.speedMax ? d.speedMax : velocidadMax;
      distanciaTotal += d.distance;
      tiempoMotor += d.tiempoEncendido;
    }
  });
  const motorH = tiempoMotor / 60 / 60;
  const motorM = (motorH - Math.floor(motorH)) * 60;
  const motorS = (motorM - Math.floor(motorM)) * 60;
  return (
    <div className="ruta-options-container">
      <DateSelector
        getData={getData}
        unidad={unidad}
        loadingData={loadingData}
        currentDayLoaded={currentDayLoaded}
        disabled={selectorDisabled}
        propDate={date}
      />
      <div className="table">
        {data.length === 0
          && (
            <Card className={classes.noContentCard}>
              <Typography variant="caption">
                No se encontraron registros de este día.
              </Typography>
            </Card>
          )}
        {data.length === 1
          && (
            <Card
              onClick={() => setRuta(data[0])}
              onKeyPress={() => {}}
              role="button"
              tabIndex={0}
            >
              <CardContent>
                <div className={classes.card}>
                  <div>
                    <Typography className="bold">
                      {data[0].horaI}
                    </Typography>
                    <Typography className="bold">
                      {data[0].horaF}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="bold orange">
                      detenido
                    </Typography>
                    <Typography>
                      vel. max.
                      {' '}
                      <span className="bold">
                        -
                      </span>
                    </Typography>
                  </div>
                  <div>
                    <Typography>
                      dist:
                      {' '}
                      <span className="bold">
                        -
                      </span>
                    </Typography>
                    <Typography>
                      tiempo:
                      {' '}
                      <span className="bold">{data[0].tiempo}</span>
                    </Typography>
                  </div>
                </div>
                <div>
                  <LinearProgress
                    color="secondary"
                    variant="determinate"
                    value={getPercentage(data[0].timeAccOn, data[0].tiempoEncendido)}
                  />
                  <Typography color="textSecondary" align="center">
                    {`Tiempo encendida: ${genTimeString(data[0].timeAccOn)} de ${genTimeString(data[0].tiempoEncendido)} `}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          )}
        {data.length > 1
          && data.map((log) => (
            <Card
              key={`${log.horaI}${log.horaF}`}
              onClick={() => setRuta(log)}
              onKeyPress={() => {}}
              role="button"
              tabIndex={0}
            >
              <CardContent>
                <div className={classes.card}>
                  <div>
                    <Typography className="bold">
                      {log.horaI}
                    </Typography>
                    <Typography className="bold">
                      {log.horaF}
                    </Typography>
                  </div>
                  <div>
                    <Typography className={generateParkedStateClassName(log)}>
                      {generateParkedStateContent(log)}
                    </Typography>
                    <Typography>
                      vel. max.
                      {' '}
                      <span className={log.speedMax > WARNINGSPEED ? 'bold darkred' : 'bold'}>
                        {(isParkedNotNull && !log.isParked) || log.speedMax > MAXSPEED ? `${Math.round(log.speedMax)}km/hr` : '-'}
                      </span>
                    </Typography>
                  </div>
                  <div>
                    <Typography>
                      dist:
                      {' '}
                      <span className="bold">
                        {(isParkedNotNull && !log.isParked) || log.speedMax > MAXSPEED ? `${Math.round(log.distance)}km` : '-'}
                      </span>
                    </Typography>
                    <Typography>
                      tiempo:
                      {' '}
                      <span className="bold">{log.tiempo}</span>
                    </Typography>
                  </div>
                </div>
                {log.isParked
                  && (
                    <div>
                      <LinearProgress
                        color="secondary"
                        variant="determinate"
                        value={getPercentage(log.timeAccOn, log.tiempoEncendido)}
                      />
                      <Typography color="textSecondary" align="center">
                        {`Tiempo encendida: ${genTimeString(log.timeAccOn)} de ${genTimeString(log.tiempoEncendido)} `}
                      </Typography>
                    </div>
                  )}
              </CardContent>
            </Card>
          ))}
        <Card onClick={setRutaAll}>
          <CardContent className="ruta-item total">
            {data.length > 0
                && (
                  <div>
                    <Typography className={classes.total}>
                      {data[0].horaI}
                    </Typography>
                    <Typography className={classes.total}>
                      {data[data.length - 1].horaF}
                    </Typography>
                  </div>
                )}
            <div>
              <Typography className={classes.total}>
                Total
              </Typography>
              <Typography className={classes.total}>
                vel. max.
                {' '}
                <span className={velocidadMax > WARNINGSPEED ? classes.totalWarn : 'bold'}>
                  {Math.round(velocidadMax)}
                  km/hr
                </span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.total}>
                dist:
                {' '}
                <span className="bold">
                  {Math.round(distanciaTotal)}
                  km
                </span>
              </Typography>
              <Typography className={classes.total}>
                tiempo:
                {' '}
                <span className="bold">
                  {`${Math.floor(motorH)}h ${Math.floor(motorM)}m ${Math.floor(motorS)}s`}
                </span>
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

RutaOptions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      distance: PropTypes.number,
      horaF: PropTypes.string,
      horaI: PropTypes.string,
      index: PropTypes.number,
      isParked: PropTypes.bool,
      speedMax: PropTypes.number,
      tiempo: PropTypes.string,
      tiempoEncendido: PropTypes.number,
      rutas: PropTypes.arrayOf(
        PropTypes.shape({
          fecha: PropTypes.string,
          fechad: PropTypes.string,
          hora: PropTypes.string,
          idRuta: PropTypes.number,
          idUnidad: PropTypes.number,
          latitud: PropTypes.number,
          longitud: PropTypes.number,
          motor: PropTypes.backgroundColor,
          velocidad: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  getData: PropTypes.func.isRequired,
  setRuta: PropTypes.func.isRequired,
  unidad: PropTypes.shape({
    idUnidad: PropTypes.number,
  }),
  isParkedNotNull: PropTypes.bool.isRequired,
  setRutaAll: PropTypes.func.isRequired,
  loadingData: PropTypes.bool.isRequired,
  currentDayLoaded: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    card: PropTypes.string,
    title: PropTypes.string,
    pos: PropTypes.string,
    total: PropTypes.string,
  }),
  selectorDisabled: PropTypes.bool,
  date: PropTypes.shape({}),
};

RutaOptions.defaultProps = {
  classes: {},
  unidad: {},
  selectorDisabled: false,
  date: {},
};

export default withStyles(styles)(RutaOptions);
