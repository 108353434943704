import React, { useState, useEffect } from 'react';

import SearchIcon from './searchIcon.svg';

const FilterBarStyle = {
  position: 'absolute',
  backgroundColor: 'white',
  zIndex: 1,
  width: '270px',
  height: '100vh',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
};

const searchBarContainer = {
  backgroundColor: '#303c64',
  width: '100%',
  height: '64px',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const searchComponent = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const filterButton = {
  backgroundColor: '#7fb03a',
  padding: '10px',
  borderRadius: '7px',
  marginTop: '7px',
  marginBottom: '15px',
  color: 'white',
  cursor: 'pointer',
};

const filterItem = {
  textAlign: 'center',
  padding: '12px 0px',
  fontWeight: 'bold',
  marginBottom: '5px',
  backgroundColor: '#e3e3e3',
  cursor: 'pointer',
};

const filterList = {
  overflow: 'scroll',
};

const FilterBar = ({
  units,
  panToUnitById,
  setMarkerFilter,
  setShowFilterBar,
}) => {
  const [filter, setFilter] = useState('');
  const [filteredUnits, setFilteredUnits] = useState(units);

  useEffect(() => {
    if (filter !== '') {
      const unidades = units.filter((u) => {
        const filters = filter.split(',');
        for (let i = 0; i < filters.length; i += 1) {
          const regex = new RegExp(`.*${filters[i]}.*`, 'gi');
          if (regex.test(u.grupos)) {
            return true;
          }
          if (regex.test(u.unidad)) {
            return true;
          }
        }
        return false;
      });
      setFilteredUnits(unidades);
    }
    if (filter === '') {
      setFilteredUnits(units);
    }
  }, [filter]);

  const getUnits = () => {
    if (filteredUnits.length > 0) return filteredUnits;
    return units;
  };

  const setGlobalFilter = () => {
    const f = `${filter}`;
    setMarkerFilter(f);
  };

  return (
    <div style={FilterBarStyle} id="filterbar-1">
      <div style={searchBarContainer}>
        <div style={searchComponent}>
          <img src={SearchIcon} alt="icono de buscar" onClick={() => setShowFilterBar(false)} />
          <input
            type="text"
            placeholder="filtro1,filtro2"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      </div>
      <div style={filterList}>
        <div
          style={filterButton}
          onClick={setGlobalFilter}
          onKeyPress={() => { }}
          role="button"
          tabIndex="0"
        >
          Implementar filtro en mapa
        </div>
        <div>
          {getUnits().map((u) => (
            <div
              style={filterItem}
              key={u.idUnidad}
              onClick={() => panToUnitById(u.idUnidad)}
              onKeyPress={() => { }}
              role="button"
              tabIndex="0"
            >
              {u.unidad}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
