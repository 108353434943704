/* eslint-disable class-methods-use-this */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MapIcon from '@material-ui/icons/Map';
import InfoIcon from '@material-ui/icons/Info';
import CarIcon from '@material-ui/icons/DirectionsCar';
import StreetViewIcon from '@material-ui/icons/Streetview';
import AdjustIcon from '@material-ui/icons/Adjust';
import ShareIcon from '@material-ui/icons/Share';
import PanoramaWideAngleIcon from '@material-ui/icons/PanoramaWideAngle';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import AlertIcon from '@material-ui/icons/NotificationsActive';
import BlockIcon from '@material-ui/icons/Block';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import BatteryChargingFull from '@material-ui/icons/BatteryChargingFull';
import SignalWifi4Bar from '@material-ui/icons/SignalWifi4Bar';
import SignalWifiOff from '@material-ui/icons/SignalWifiOff';
import LocationSearching from '@material-ui/icons/LocationSearching';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import red from '@material-ui/core/colors/red';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import WappSvg from '../whatsapp.svg';

import Snackbar from '../Components/Snackbar';
import GeneralInfoForm from './GeneralInfoForm';

import SECRETS from '../secrets';

import isMobile from '../Utils/DeviceDetect';

const styles = (theme) => ({
  colorSwitchBase: {
    '&$colorChecked': {
      color: red[500],
      '& + $colorBar': {
        backgroundColor: red[500],
      },
    },
  },

  colorBar: {},

  colorChecked: {},

  listContainer: {
    width: 280,
  },

  list: {
    '&:last-child': {
      marginBottom: theme.spacing.unit * 2,
    },
  },

  nested_list: {
    paddingRight: 0,
    marginRight: 0,
    width: '100%',
  },

  nested_list_item: {
    paddingTop: 0,
  },

  paper: {
    margin: 20,
  },

  paperWidthXs: {
    '&$paperScrollBody': {
      margin: 20,
    },
  },

  paperScrollBody: {},

  dialog_options: {
    marginTop: 15,
  },

  alertsList: {
    marginBottom: 15,
  },

  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
    minWidth: 120,
  },

  linkStyle: {
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
    fontSize: '0.8em',
    color: '#303C64',
  },

  verde: {
    color: '#538659',
  },
  morado: {
    color: '#654A74',
  },
  amarillo: {
    color: '#ACAD6B',
  },
  rojo: {
    color: '#AD6E6B',
  },
  azul: {
    color: '#485E70',
  },
  cafe: {
    color: '#AD846B',
  },

  compartirContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '270px',
  },
  compartirUnidades: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  compartirUnidadesTextField: {
    width: '50%',
  },
  TextField: {
    width: '100%',
  },
  botonCopiar: {
    height: '56px',
    marginTop: '9px',
    marginLeft: '7px',
  },
  editButton: {
    float: 'right',
  },
});

const btnStyle = {
  width: '100%',
  height: '100%',
};

const motorBlockingOptions = [
  { id: '0', title: 'Prefiero hacerlo manual', brief: 'Manual' },
  { id: '1', title: 'Bloquear el motor a los', brief: '' },
  { id: '2', title: 'Al apagar el motor', brief: 'Al apagar' },
];

const motorLockTimesOptions = [5, 15, 30, 45, 60];

const roundCoords = (coord) => {
  let tmp = coord * 1000000;
  tmp = Math.round(tmp);
  return tmp / 1000000;
};

class UnitPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goToRoutes: false,
      unitLockMode: 0,
      motorBlockingDialog: false,
      unitLockTime: 0,
      snack: false,
      snackMensaje: '',
      anchorEl: null,
      compartirTiempo: 1,
      unidadTiempo: 'h',
      compartirDialog: false,
      editMode: false,
    };
  }

  snackClose = () => {
    this.setState({ snack: false });
  };

  openSnack = (snackMensaje) => {
    this.setState({
      snack: true,
      snackMensaje,
    });
  };

  setUnitLockMode = () => {
    const { unit } = this.props;
    const unitLockMode = unit.modoBloqueo;
    let unitLockTime = motorLockTimesOptions[0];
    if (unitLockMode === 1) {
      unitLockTime = unit.tiempoBloqueo;
    }
    this.setState({ unitLockMode, unitLockTime });
  };

  // Compartir dialog handlers
  handleCompartirDialogOpen = () => this.setState({ compartirDialog: true });

  handleCompartirCancel = () => {
    this.setState({ compartirDialog: false });
  };

  generarTokenCompartir = (callback) => {
    const { unit, setProcessInProgress } = this.props;
    const { compartirTiempo, unidadTiempo } = this.state;
    let time = 0; // in seconds
    switch (unidadTiempo) {
      case 'h':
        time = compartirTiempo * 60 * 60;
        break;
      case 'm':
        time = compartirTiempo * 60;
        break;
      default:
        break;
    }

    setProcessInProgress(true);
    const { getToken } = this.props;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios
      .post('/track/newtoken', { idUnidad: unit.idUnidad, time })
      .then((resp) => {
        const { token: newToken } = resp.data;
        callback(newToken);
        setProcessInProgress(false);
      })
      .catch(() => {
        setProcessInProgress(false);
      });
  };

  handleCompartirWhatsApp = () => {
    if (isMobile()) {
      this.generarTokenCompartir((token) => {
        window.location.href = `https://api.whatsapp.com/send?text=https%3A%2F%2Ftracker-app.caebes.com/%23/${token}`;
      });
    } else {
      const winRef = window.open();
      this.generarTokenCompartir((token) => {
        winRef.location.href = `https://api.whatsapp.com/send?text=https%3A%2F%2Ftracker-app.caebes.com/%23/${token}`;
      });
    }
  };

  handleCompartirCopy = () => {
    const { setProcessInProgress } = this.props;
    setProcessInProgress(true);
    this.generarTokenCompartir((token) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(`https://tracker-app.caebes.com/#/${token}`).then(() => {
          this.openSnack('Enlace copiado.');
          setProcessInProgress(false);
        }, (err) => {
          console.error('Async: Could not copy text: ', err);
          setProcessInProgress(false);
        });
      }
    });
  };

  /** START Motor Shutdown Dialog handlers */
  handleMotorBlockingDialogOpen = () => this.setState({ motorBlockingDialog: true });

  handleMotorBlockingOptionsChange = (event, checked) => {
    const unitLockMode = parseInt(motorBlockingOptions[checked].id, 10);
    this.setState({ unitLockMode });
  };

  handleMotorBlockingCancel = () => {
    const { unit } = this.props;
    const unitLockMode = unit.modoBloqueo;
    this.setState({ unitLockMode, motorBlockingDialog: false });
  };

  handleMotorBlockingOk = () => {
    const {
      unit,
      setProcessInProgress,
      handleUnitChange,
      getToken,
    } = this.props;
    const { unitLockMode, unitLockTime } = this.state;

    let unitLockModeValues = {
      modoBloqueo: unitLockMode,
      modoGeocerca: unitLockMode,
    };

    if (unitLockMode === 1) {
      unitLockModeValues = {
        tiempoBloqueo: unitLockTime,
        ...unitLockModeValues,
      };
    }

    setProcessInProgress(true);

    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios
      .patch(`/unidades/${unit.idUnidad}`, unitLockModeValues)
      .then(() => {
        handleUnitChange(unitLockModeValues);
        this.setState({ motorBlockingDialog: false });
        setProcessInProgress(false);
      })
      .catch(() => {
        setProcessInProgress(false);
      });
  };

  updateUnitAlarms = (alarm) => (event) => {
    const {
      unit,
      setProcessInProgress,
      handleUnitChange,
      getToken,
    } = this.props;

    const alarms = {};
    alarms[`${alarm}Alarm`] = event.target.checked;

    setProcessInProgress(true);

    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios
      .patch(`/unidades/${unit.idUnidad}`, alarms)
      .then(() => {
        setProcessInProgress(false);
        handleUnitChange(alarms);
      })
      .catch(() => {
        setProcessInProgress(false);
      });
  };

  updateUnitAutoBlocking = (event) => {
    const {
      unit,
      setProcessInProgress,
      handleUnitChange,
      getToken,
    } = this.props;

    setProcessInProgress(true);

    const unitAutoBlocking = { autoBloqueo: event.target.checked };

    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios
      .patch(`/unidades/${unit.idUnidad}`, unitAutoBlocking)
      .then(() => {
        setProcessInProgress(false);
        handleUnitChange(unitAutoBlocking);
      })
      .catch(() => {
        setProcessInProgress(false);
      });
  };

  handleTraceUnitRoute = (unidad) => {
    const { setUnidadRuta } = this.props;
    setUnidadRuta(unidad);
    this.setState({ goToRoutes: true });
  };

  handleLockTimeDialogOptionChange = (event) => {
    const unitLockTime = event.target.value;
    this.setState({ unitLockTime });
  };

  handleLockUnitDialogEntering = () => {
    this.radioGroupRef.focus();
  };

  getNewToken = (oldToken, idUnidad) => {
    const { setProcessInProgress, updateSafetyToken, getToken } = this.props;
    setProcessInProgress(true);
    const requestObj = {
      prevtoken: oldToken,
      id: idUnidad,
    };

    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.defaults.headers.common.Authorization = `bearer ${getToken}`;
    axios
      .post('/shortcuts/generate', requestObj)
      .then((response) => {
        setProcessInProgress(false);
        updateSafetyToken(response.data.token, idUnidad);
      })
      .catch(() => {
        setProcessInProgress(false);
      });
  };

  requestPosition = (imei) => {
    const { setProcessInProgress, getToken } = this.props;
    const comando1 = 'gpson';
    const comando2 = 'position';

    setProcessInProgress(true);

    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios
      .post(`/commands/${imei}`, { command: comando1 })
      .then(() => {
        this.openSnack('Dispositivo ubicado');
        setProcessInProgress(false);
      })
      .catch(() => {
        // this.openSnack('El gps no está conectado');
        setProcessInProgress(false);
      });
    axios
      .post(`/commands/${imei}`, { command: comando2 })
      .then(() => {
        this.openSnack('Dispositivo ubicado');
        setProcessInProgress(false);
      })
      .catch(() => {
        // this.openSnack('El gps no está conectado');
        setProcessInProgress(false);
      });
  };

  closeColorMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleColorMenuButton = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  saveNewColor = (color) => {
    const {
      setProcessInProgress,
      unit,
      getUnidades,
      updateCurrentUnitColor,
      getToken,
    } = this.props;
    setProcessInProgress(true);
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios
      .patch(`/unidades/${unit.idUnidad}`, { color })
      .then(() => {
        this.openSnack('El color de la geocerca ha sido cambiado.');
        setProcessInProgress(false);
        this.setState({ anchorEl: null });
        getUnidades();
        updateCurrentUnitColor(color);
      })
      .catch(() => {
        this.openSnack('Hubo un problema al cambiar el color, intente nuevamente.');
        setProcessInProgress(false);
        this.setState({ anchorEl: null });
      });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  toggleEditMode = () => {
    const { editMode } = this.state;
    this.setState({
      editMode: !editMode,
    });
  };

  timeToHumanReadable = (ms) => {
    const hrs = ms / 60 / 60 / 1000;
    const min = (hrs - Math.floor(hrs)) * 60;
    const sec = (min - Math.floor(min)) * 60;
    return `${Math.floor(hrs)}h${Math.floor(min)}m${Math.floor(sec)}s`;
  };

  render() {
    const {
      opened,
      unit,
      onCloseHandler,
      onBlockUnitHandler,
      setPanorama,
      classes,
      updateUnidad,
      getToken,
      getPermissionLevel,
    } = this.props;
    const {
      goToRoutes,
      motorBlockingDialog,
      compartirDialog,
      unitLockMode,
      unitLockTime,
      snack,
      snackMensaje,
      anchorEl,
      compartirTiempo,
      unidadTiempo,
      editMode,
    } = this.state;
    if (goToRoutes) return <Redirect to="/ruta" />;
    return (
      <div>
        <Drawer
          anchor="right"
          open={opened}
          onClose={onCloseHandler}
          onRendered={this.setUnitLockMode}
        >
          <div className={classes.listContainer}>
            {editMode && (
              <GeneralInfoForm
                unit={unit}
                updateUnidad={updateUnidad}
                toggleEditMode={this.toggleEditMode}
                getToken={getToken}
              />
            )}
            {!editMode && (
              <List
                dense
                className={classes.list}
                subheader={(
                  <ListSubheader disableSticky>
                    Información general
                    <EditIcon className={classes.editButton} onClick={this.toggleEditMode} />
                  </ListSubheader>
                )}
              >
                <ListItem>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Nombre" secondary={unit.unidad} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Grupos" secondary={unit.grupos} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Velocidad" secondary={`${unit.velocidad}km/h`} />
                </ListItem>
                {unit.isKm && (
                  <div>
                    <ListItem>
                      <ListItemText primary="Distancia desde último servicio" secondary={`${unit.kilometraje}km`} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Rendimiento" secondary={`${unit.combustible}km/lt`} />
                    </ListItem>
                  </div>
                )}
                {!unit.isKm && (
                  <div>
                    <ListItem>
                      <ListItemText primary="Horas de trabajo desde último servicio" secondary={`${this.timeToHumanReadable(unit.horasTrabajo)}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Rendimiento" secondary={`${unit.rendimientoHora}lts/hr`} />
                    </ListItem>
                  </div>
                )}
                {unit.voltage && (
                  <ListItem>
                    <ListItemIcon>
                      <BatteryChargingFull />
                    </ListItemIcon>
                    <ListItemText primary="Batería" secondary={`${unit.voltage}%`} />
                  </ListItem>
                )}
                {(unit.gpsModel === 'qbit' || unit.gpsModel === 'concox' || unit.gpsModel === 'concox3g') && (
                  <ListItem>
                    {unit.conectado && (
                      <ListItemIcon>
                        <SignalWifi4Bar />
                      </ListItemIcon>
                    )}
                    {!unit.conectado && (
                      <ListItemIcon>
                        <SignalWifiOff />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={unit.conectado ? 'Conectado' : 'Desconectado'} />
                  </ListItem>
                )}
              </List>
            )}
            <Divider />
            <List
              dense
              className={classes.list}
              subheader={<ListSubheader disableSticky>Acciones</ListSubheader>}
            >
              {unit.gpsModel === 'qbit' && (
                <ListItem
                  button
                  onClick={() => {
                    this.requestPosition(unit.imei);
                  }}
                >
                  <ListItemIcon>
                    <LocationSearching />
                  </ListItemIcon>
                  <ListItemText primary="Pedir ubicación" />
                </ListItem>
              )}
              <ListItem
                button
                onClick={() => {
                  setPanorama({
                    lat: roundCoords(unit.latitud),
                    lng: roundCoords(unit.longitud),
                  });
                }}
              >
                <ListItemIcon>
                  <StreetViewIcon />
                </ListItemIcon>
                <ListItemText primary="Ver en Street View" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  this.handleTraceUnitRoute(unit);
                }}
              >
                <ListItemIcon>
                  <MapIcon />
                </ListItemIcon>
                <ListItemText primary="Trazar recorrido" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to={`unit/${unit.idUnidad}/polyfence/${unit.idGeocerca || 0}`}
              >
                <ListItemIcon>
                  <PanoramaWideAngleIcon />
                </ListItemIcon>
                <ListItemText primary="Polígono" />
              </ListItem>
              {((unit.gpsModel === 'concox' || unit.gpsModel === 'concox3g') && parseInt(getPermissionLevel(), 10) > 0) && (
                <ListItem
                  button
                  component={Link}
                  to={`unit/${unit.idUnidad}/${unit.unidad}/autoBlocking`}
                >
                  <ListItemIcon>
                    <AlarmOffIcon />
                  </ListItemIcon>
                  <ListItemText primary="Auto Bloqueo" />
                  <ListItemSecondaryAction>
                    <Switch
                      onChange={this.updateUnitAutoBlocking}
                      checked={unit.autoBloqueo}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              {((unit.gpsModel === 'concox' || unit.gpsModel === 'concox3g') && parseInt(getPermissionLevel(), 10) > 0) && (
                <>
                  <ListItem button onClick={() => { this.handleMotorBlockingDialogOpen(); }}>
                    <ListItemIcon>
                      <BlockIcon />
                    </ListItemIcon>
                    {motorBlockingOptions[unit.modoBloqueo]
                      && <ListItemText primary="Bloqueo de motor" secondary={(unit.modoBloqueo === 1) ? `A los ${unit.tiempoBloqueo} min.` : motorBlockingOptions[unit.modoBloqueo].brief} />}
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => { this.handleMotorBlockingDialogOpen(); }}>
                        <MoreVertIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <List dense className={classes.nested_list}>
                      <ListItem className={classes.nested_list_item}>
                        <ListItemText primary="Bloquear motor" />
                        <ListItemSecondaryAction>
                          <Switch
                            onChange={() => onBlockUnitHandler()}
                            checked={unit.electricity}
                            classes={{
                              switchBase: classes.colorSwitchBase,
                              checked: classes.colorChecked,
                              bar: classes.colorBar,
                            }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </ListItem>
                </>
              )}
            </List>
            { (unit.gpsModel === 'concox' || unit.gpsModel === 'qbit' || unit.gpsModel === 'concox3g') && (
              <>
                <Divider />
                <List
                  dense
                  className={classes.list}
                  subheader={<ListSubheader disableSticky>Notificaciones</ListSubheader>}
                >
                  { (unit.gpsModel === 'concox' || unit.gpsModel === 'concox3g') && (
                    <ListItem>
                      <ListItemIcon>
                        <AlertIcon />
                      </ListItemIcon>
                      <ListItemText primary="Alerta de movimiento" />
                      <ListItemSecondaryAction>
                        <Switch
                          onChange={this.updateUnitAlarms('shock')}
                          checked={unit.shockAlarm}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemIcon>
                      <AlertIcon />
                    </ListItemIcon>
                    <ListItemText primary="Alerta de velocidad máxima" />
                    <ListItemSecondaryAction>
                      <Switch
                        onChange={this.updateUnitAlarms('speed')}
                        checked={unit.speedAlarm}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </>
            )}
            { (unit.gpsModel === 'concox' || unit.gpsModel === 'qbit' || unit.gpsModel === 'concox3g') && (
              <>
                <Divider />
                <List
                  dense
                  className={classes.list}
                  subheader={<ListSubheader disableSticky>Configuración</ListSubheader>}
                >
                  <ListItem button component={Link} to={`unit/${unit.idUnidad}/geofence`}>
                    <ListItemIcon>
                      <AdjustIcon />
                    </ListItemIcon>
                    <ListItemText primary="Geo Cercas" />
                  </ListItem>
                  <ListItem onClick={this.handleColorMenuButton}>
                    <ListItemIcon>
                      <Brightness1Icon
                        style={{
                          color: unit.color,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Color geocerca" />
                  </ListItem>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.closeColorMenu}
                  >
                    <MenuItem onClick={() => this.saveNewColor('#538659')} className={classes.verde}>Verde</MenuItem>
                    <MenuItem onClick={() => this.saveNewColor('#654A74')} className={classes.morado}>Morado</MenuItem>
                    <MenuItem onClick={() => this.saveNewColor('#ACAD6B')} className={classes.amarillo}>Amarillo</MenuItem>
                    <MenuItem onClick={() => this.saveNewColor('#AD6E6B')} className={classes.rojo}>Rojo</MenuItem>
                    <MenuItem onClick={() => this.saveNewColor('#485E70')} className={classes.azul}>Azul</MenuItem>
                    <MenuItem onClick={() => this.saveNewColor('#AD846B')} className={classes.cafe}>Café</MenuItem>
                  </Menu>
                  {unit.gpsModel === 'qbit' && (
                    <ListItem button component={Link} to={`unit/${unit.idUnidad}/sosnumber`}>
                      <ListItemIcon>
                        <PhoneInTalkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Número SOS" />
                    </ListItem>
                  )}
                  <ListItem button component={Link} to={`unit/${unit.idUnidad}/overspeed`}>
                    <ListItemIcon>
                      <SvgIcon>
                        <path d="M12,16A3,3 0 0,1 9,13C9,11.88 9.61,10.9 10.5,10.39L20.21,4.77L14.68,14.35C14.18,15.33 13.17,16 12,16M12,3C13.81,3 15.5,3.5 16.97,4.32L14.87,5.53C14,5.19 13,5 12,5A8,8 0 0,0 4,13C4,15.21 4.89,17.21 6.34,18.65H6.35C6.74,19.04 6.74,19.67 6.35,20.06C5.96,20.45 5.32,20.45 4.93,20.07V20.07C3.12,18.26 2,15.76 2,13A10,10 0 0,1 12,3M22,13C22,15.76 20.88,18.26 19.07,20.07V20.07C18.68,20.45 18.05,20.45 17.66,20.06C17.27,19.67 17.27,19.04 17.66,18.65V18.65C19.11,17.2 20,15.21 20,13C20,12 19.81,11 19.46,10.1L20.67,8C21.5,9.5 22,11.18 22,13Z" />
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary="Velocidad máxima" />
                  </ListItem>
                  {(unit.gpsModel === 'concox' || unit.gpsModel === 'concox3g') && (
                    <ListItem
                      button
                      onClick={() => this.getNewToken(unit.safetyToken, unit.idUnidad)}
                    >
                      <ListItemIcon>
                        <AddToHomeScreenIcon />
                      </ListItemIcon>
                      <ListItemText primary="Generar nuevo acceso directo" />
                    </ListItem>
                  )}
                  {(unit.gpsModel === 'concox' || unit.gpsModel === 'concox3g') && (
                    <ListItem>
                      <ListItemIcon>
                        <SubdirectoryArrowRightIcon />
                      </ListItemIcon>
                      <a className={classes.linkStyle} target="blank" href={`${SECRETS.SERVERURL}/shortcuts/${unit.safetyToken}/${unit.idUnidad}`}>Abrir acceso directo</a>
                    </ListItem>
                  )}
                </List>
              </>
            )}
            {(unit.gpsModel === 'tk103' && parseInt(getPermissionLevel(), 10) > 0) && (
              <ListItem>
                <List dense className={classes.nested_list}>
                  <ListItem className={classes.nested_list_item}>
                    <ListItemText primary="Bloquear motor" />
                    <ListItemSecondaryAction>
                      <Switch
                        onChange={() => onBlockUnitHandler()}
                        checked={unit.electricity}
                        classes={{
                          switchBase: classes.colorSwitchBase,
                          checked: classes.colorChecked,
                          bar: classes.colorBar,
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </ListItem>
            )}
            <Divider />
            <List
              dense
              className={classes.list}
              subheader={<ListSubheader disableSticky>Compartir</ListSubheader>}
            >
              <ListItem button onClick={this.handleCompartirDialogOpen}>
                <ListItemIcon>
                  <ShareIcon />
                </ListItemIcon>
                <ListItemText primary="Ubicación" />
              </ListItem>
            </List>
          </div>
        </Drawer>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          onEntering={this.handleLockUnitDialogEntering}
          aria-labelledby="motor-shutdown-dialog-title"
          aria-describedby="motor-shutdown-dialog-description"
          open={motorBlockingDialog}
          classes={{
            paper: classes.paper,
            paperWidthXs: classes.paperWidthXs,
          }}
        >
          <DialogTitle id="motor-shutdown-dialog-title">Bloqueo de Motor</DialogTitle>
          <DialogContent>
            <DialogContentText id="motor-shutdown-dialog-description">
              Elige la manera en la que quieres bloquear el motor
            </DialogContentText>
            <RadioGroup
              ref={(ref) => {
                this.radioGroupRef = ref;
              }}
              className={classes.dialog_options}
              aria-label="Apagado de Motor"
              name="Apagado de Motor"
              value={`${unitLockMode}`}
              onChange={(event, checked) => this.handleMotorBlockingOptionsChange(event, checked)}
            >
              {motorBlockingOptions.map((option) => {
                const jsx = [];
                jsx.push(<FormControlLabel value={option.id} key={option.id} control={<Radio color="secondary" />} label={option.title} />);
                if (option.id === '1') {
                  jsx.push(
                    <form className={classes.form} noValidate autoComplete="off">
                      <FormControl className={classes.formControl}>
                        <Select
                          value={unitLockTime}
                          onChange={this.handleLockTimeDialogOptionChange}
                          inputProps={{
                            name: 'lock-time',
                            id: 'lock-time',
                          }}
                          disabled={!(unitLockMode === 1)}
                        >
                          <MenuItem value={5}>5 min</MenuItem>
                          <MenuItem value={15}>15 min</MenuItem>
                          <MenuItem value={30}>30 min</MenuItem>
                          <MenuItem value={45}>40 min</MenuItem>
                          <MenuItem value={60}>60 min</MenuItem>
                        </Select>
                      </FormControl>
                    </form>,
                  );
                }
                return jsx;
              })}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleMotorBlockingCancel()} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => this.handleMotorBlockingOk()} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="compartir-dialog-title"
          open={compartirDialog}
          classes={{
            paper: classes.paper,
            paperWidthXs: classes.paperWidthXs,
          }}
        >
          <DialogTitle id="compartir-dialog-title">Compartir ubicación</DialogTitle>
          <DialogContent>
            <div className={classes.compartirContainer}>
              <div className={classes.compartirUnidades}>
                <TextField
                  id="outlined-search"
                  label="Expiración dentro de"
                  type="number"
                  className={classes.compartirUnidadesTextField}
                  margin="normal"
                  variant="outlined"
                  value={compartirTiempo}
                  onChange={this.handleChange('compartirTiempo')}
                />
                <TextField
                  id="outlined-select-unidad"
                  select
                  label="Unidad"
                  className={classes.compartirUnidadesTextField}
                  value={unidadTiempo}
                  onChange={this.handleChange('unidadTiempo')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {[{
                    label: 'Horas',
                    value: 'h',
                  }, {
                    label: 'Minutos',
                    value: 'm',
                  }].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: '70%' }}>
                      <Typography variant="button" display="block" gutterBottom>
                        Compartir whatsapp
                      </Typography>
                    </td>
                    <td>
                      <Button
                        onClick={this.handleCompartirWhatsApp}
                      >
                        <img
                          style={btnStyle}
                          src={WappSvg}
                          alt="whatsappIcon"
                        />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '70%' }}>
                      <Typography variant="button" display="block" gutterBottom>
                        Copiar enlace
                      </Typography>
                    </td>
                    <td>
                      <Button
                        variant="outlined"
                        color="primary"
                        aria-label="Copiar"
                        className={classes.botonCopiar}
                        onClick={() => this.handleCompartirCopy()}
                      >
                        <FileCopyIcon />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCompartirCancel()} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snack}
          close={this.snackClose}
        >
          {snackMensaje}
        </Snackbar>
      </div>
    );
  }
}

UnitPanel.propTypes = {
  opened: PropTypes.bool.isRequired,
  unit: PropTypes.shape({
    unidad: PropTypes.string,
    latitud: PropTypes.number,
    longitud: PropTypes.number,
    velocidad: PropTypes.number,
    hora: PropTypes.string,
    info: PropTypes.bool,
    idUnidad: PropTypes.number,
    motor: PropTypes.bool,
    voltage: PropTypes.number,
    conectado: PropTypes.bool,
    isKm: PropTypes.bool,
    kilometraje: PropTypes.number,
    horasTrabajo: PropTypes.number,
    combustible: PropTypes.number,
    rendimientoHora: PropTypes.number,
    idGeocerca: PropTypes.number,
    autoBloqueo: PropTypes.bool,
  }),
  setPanorama: PropTypes.func.isRequired,
  setUnidadRuta: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  onBlockUnitHandler: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  handleUnitChange: PropTypes.func.isRequired,
  setProcessInProgress: PropTypes.func.isRequired,
  updateSafetyToken: PropTypes.func.isRequired,
  updateUnidad: PropTypes.func.isRequired,
  updateCurrentUnitColor: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
  getUnidades: PropTypes.func.isRequired,
};

UnitPanel.defaultProps = {
  unit: {},
  classes: {},
};

export default withStyles(styles)(UnitPanel);
