import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';

import marker from './marker-small.png';
import markerOff from './marker-small-off.png';
import markerOffTiny from './marker-tiny-off.png';
import markerLock from './marker-small-lock.png';
import markerLockTiny from './marker-tiny-lock.png';
import markerPersonal from './marker-small-personal.png';
import markerPersonalTiny from './marker-tiny-personal.png';
import markerTiny from './marker-tiny.png';

const getIcon = (motor, locked, personal, scale) => {
  const markerPersonalIcon = scale === 'small' ? markerPersonalTiny : markerPersonal;
  const markerLockIcon = scale === 'small' ? markerLockTiny : markerLock;
  const markerOffIcon = scale === 'small' ? markerOffTiny : markerOff;
  const markerIcon = scale === 'small' ? markerTiny : marker;
  if (personal) return markerPersonalIcon;
  if (locked) return markerLockIcon;
  return motor ? markerIcon : markerOffIcon;
};

const CustomMarker = ({
  position,
  info,
  id,
  motor,
  locked,
  personal,
  label,
  visible,
  children,
  scale,
}) => (
  <Marker
    position={position}
    onClick={() => info(id)}
    icon={{ url: getIcon(motor, locked, personal, scale), labelOrigin: { x: 23, y: -5 } }}
    label={{ text: label, color: '#1c3466', fontWeight: 'bold' }}
    visible={visible}
  >
    {children}
  </Marker>
);

CustomMarker.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  info: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  motor: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  personal: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

CustomMarker.defaultProps = {
  personal: false,
};

export default CustomMarker;
