/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import grey from '@material-ui/core/colors/grey';
import Tooltip from '@material-ui/core/Tooltip';

import FA from 'react-fontawesome';
import * as moment from 'moment';
import 'moment/locale/es';

import SECRETS from '../secrets';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  container: {
    padding: theme.spacing.unit,
    height: '70%',
    overflow: 'auto',
  },
  card: {
    marginBottom: theme.spacing.unit * 2,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardHeader: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  cardContent: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  cardActions: {
    display: 'flex',
  },
  cardAvatar: {
    backgroundColor: grey,
  },
  cardAvatarActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  zoneSwitch: {
    marginLeft: 'auto',
  },
  dateSelector: {
    padding: theme.spacing.unit,
    height: '20%',
  },
  loadButton: {
    margin: theme.spacing.unit,
    display: 'block',
    width: '96%',
    height: '8%',
  },
});

const ZoneReport = ({
  getId,
  setProcessInProgress,
  getToken,
  classes,
}) => {
  const [goToMapScreen, setGoToMapScreen] = useState(false);
  const [zonas, setZonas] = useState([]);
  const [defaultDate, setDefaultDate] = useState(moment());
  const [daysFromToday, setDaysFromToday] = useState(0);

  useEffect(() => {
    const userId = parseInt(getId(), 10);
    if (!userId || userId < 1) {
      setGoToMapScreen(true);
    }
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/zonas/usuario/${userId}`)
      .then((response) => {
        const tempZonas = response.data;
        if (tempZonas) {
          setZonas(tempZonas);
        }
        setProcessInProgress(false);
      })
      .catch(() => {
        setProcessInProgress(false);
      });
  }, []);

  const goBack = () => setGoToMapScreen(true);

  const updateZoneStatus = (index) => {
    setProcessInProgress(true);
    const tempZonas = [...zonas];
    const zone = tempZonas[index];
    const activated = !zone.activa;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .patch(`/zonas/${zone.id}`, { activa: activated })
      .then(() => {
        // Save changes on State
        tempZonas[index] = { ...zone, activa: activated };
        setZonas(tempZonas);
        setProcessInProgress(false);
      })
      .catch((error) => {
        console.error(error.response.data.error);
        setProcessInProgress(false);
      });
  };

  const prevDate = () => {
    setDefaultDate(defaultDate.subtract(1, 'days'));
    setDaysFromToday(daysFromToday - 1);
  };

  const nextDate = () => {
    if (daysFromToday < 0) {
      setDefaultDate(defaultDate.add(1, 'days'));
      setDaysFromToday(daysFromToday + 1);
    }
  };

  const generarReporte = () => {
    // eslint-disable-next-line no-undef
    window.location.href = `${SECRETS.SERVERURL}/sheets/reportezonas/{"userId":"${getId()}", "date":"${defaultDate.format('YYYY-MM-DD')}"}`;
  };

  if (getId() < 1) return <Redirect to="/" />;
  if (goToMapScreen) return <Redirect to="/map" />;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Back"
            onClick={goBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Reporte de Zonas
          </Typography>
          {/* { (geofences && (geofences.length < geofencesLimit)) && ( */}
          <Tooltip title="Agregar Zona">
            <IconButton
              component={Link}
              to="/user/zone/create"
              color="inherit"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          {/* )} */}
        </Toolbar>
      </AppBar>
      <main className={classes.container}>
        {zonas && (
          zonas.map((zone, index) => (
            <Card className={classes.card} key={zone.id}>
              <CardHeader
                className={classes.cardHeader}
                avatar={<Avatar aria-label="Zona" className={(zone.activa) ? classes.cardAvatarActive : classes.cardAvatar}> Z </Avatar>}
                title={zone.nombre}
                subheader={`${zone.radio} m.`}
              />
              <CardContent className={classes.cardContent}>
                <Typography>
                  {zone.direccion}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions} disableActionSpacing>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => updateZoneStatus(index)}
                >
                  {(zone.activa) ? 'Desactivar' : 'Activar'}
                </Button>
                <Button
                  size="small"
                  color="primary"
                  component={Link}
                  to={`/user/zone/edit/${zone.id}`}
                  disabled={!zone.activa}
                >
                  Editar
                </Button>
              </CardActions>
            </Card>
          ))
        )}

      </main>
      <div>
        <div className="selector">
          <div className="btn-left">
            <FA className="menu-btn" name="chevron-left" onClick={prevDate} />
          </div>
          <div className="date-selector">
            <span className="dia">{defaultDate.format('ddd')}</span>
            <span className="fecha">{defaultDate.format('DD')}</span>
            <div className="month-year">
              <span className="mes">{defaultDate.format('MMM')}</span>
              <span className="year">{defaultDate.format('YYYY')}</span>
            </div>
          </div>
          <div className={daysFromToday < 0 ? 'btn-right' : 'btn-right-disabled'}>
            <FA className="menu-btn" name="chevron-right" onClick={nextDate} />
          </div>
        </div>
      </div>
      <Button
        variant="contained"
        color="secondary"
        className={classes.loadButton}
        onClick={generarReporte}
      >
        Generar Reporte
      </Button>
    </div>
  );
};

ZoneReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string,
    }),
  }),
  getId: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  setProcessInProgress: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

ZoneReport.defaultProps = {
  classes: {},
  match: {},
};

export default withStyles(styles)(ZoneReport);
