/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';

import SECRETS from '../secrets';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  container: {
    position: 'relative',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  button: {
    width: '100%',
    marginBottom: theme.spacing.unit * 2,
  },
  description: {
    marginTop: theme.spacing.unit * 2,
  },
  closeButton: {
    padding: theme.spacing.unit / 2,
  },
});

class OverSpeedScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitId: undefined,
      speedLimit: undefined,
      alertOpen: false,
      alertMessage: '',
    };
  }

  componentDidMount() {
    const { getId, setProcessInProgress, match: { params } } = this.props;

    setProcessInProgress(true);

    // Validate User is logged
    const userId = parseInt(getId(), 10);
    if (!userId || userId < 1) this.goToMapScreen();

    // Validate unitId is a valid number
    let { unitId } = params;
    if (!unitId || !this.isNumeric(unitId)) this.goToMapScreen();

    unitId = parseInt(unitId, 10);

    // Validate Unit of unitId belongs to user
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/unidades/${unitId}`)
      .then((response) => {
        const unit = response.data;

        // Return to MapScreen if Unit does not belongs to User or does not exists
        if (!unit || (userId !== unit.idUsuario)) this.goToMapScreen();

        const speedLimit = unit.velocidadMaxima;

        this.setState({ unitId, speedLimit });

        setProcessInProgress(false);
      })
      .catch((error) => {
        console.error(error);
        setProcessInProgress(false);
      });
  }

  // NOTE: goBack() on props
  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  // NOTE: goToMapScreen() on props
  goToMapScreen = () => {
    const { history } = this.props;
    history.push('/map');
  };

  // NOTE: Pass as props or helper
  // eslint-disable-next-line class-methods-use-this
  isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

  handleCloseAlert = () => {
    this.setState({ alertOpen: false });
  };

  handleChangeSpeedLimit = (event) => {
    const speedLimit = event.target.value;

    // Update State only if
    // a. Is number and less than 3 charactes [999]
    // c. Empty string
    if ((/^[0-9]+$/.test(speedLimit) && speedLimit.length <= 3) || speedLimit === '') {
      this.setState({ speedLimit });
    }
  };

  validateSpeedLimit = (speedLimit) => {
    if (!this.isNumeric(speedLimit)) return { result: false, error: 'Límite de velocidad no válido.' };
    if (speedLimit < 15) return { result: false, error: 'El límite debe ser mayor a 15.' };
    if (speedLimit > 255) return { result: false, error: 'El límite debe ser menor a 255.' };

    return { result: true };
  };

  updateSpeedLimit = () => {
    const { setProcessInProgress } = this.props;
    const { unitId } = this.state;
    let { speedLimit } = this.state;

    setProcessInProgress(true);

    speedLimit = parseInt(speedLimit, 10);

    const validation = this.validateSpeedLimit(speedLimit);

    if (!validation.result) {
      this.setState({ alertOpen: true, alertMessage: validation.error });
      setProcessInProgress(false);
      return;
    }

    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .patch(`/unidades/${unitId}`, {
        velocidadMaxima: speedLimit,
      })
      .then((response) => {
        const alert = { alertOpen: true, alertMessage: 'Límite de velocidad guardado.' };
        if (response.status !== 200) alert.alertMessage = 'Error, vuelve a intentarlo más tarde.';

        this.setState(alert);
        setProcessInProgress(false);
      })
      .catch((error) => {
        console.error(error.response.data.error);
        this.setState({
          alertOpen: true,
          alertMessage: 'Error, vuelve a intentarlo más tarde.',
        });
        setProcessInProgress(false);
      });
  };

  render() {
    const {
      speedLimit,
      alertOpen,
      alertMessage,
    } = this.state;
    const { classes } = this.props;
    return (
      <>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="Back"
              onClick={this.goBack}
              className={classes.menuButton}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Velocidad máxima
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.container}>
          <form className={classes.form}>
            <FormControl className={classes.textField}>
              <InputLabel htmlFor="adornment-password">Límite de velocidad</InputLabel>
              <Input
                id="speed-limit"
                value={speedLimit || ''}
                onChange={this.handleChangeSpeedLimit}
                endAdornment={<InputAdornment position="end">Km/h</InputAdornment>}
                inputProps={{
                  'aria-label': 'Speed Limit',
                }}
              />
              <FormHelperText id="unit-speed-limit">min 15 - max 255</FormHelperText>
            </FormControl>
            <Button
              variant="contained"
              className={classes.button}
              color="secondary"
              onClick={this.updateSpeedLimit}
            >
              Guardar
            </Button>
          </form>
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.description}
          >
            Se enviará una alerta cuando se rebase el límite de velocidad.
          </Typography>
        </main>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={alertOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseAlert}
          ContentProps={{
            'aria-describedby': 'alert-message',
            headlineMapping: {
              body1: 'div',
              body2: 'div',
            },
          }}
          message={<span id="alert-message">{alertMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.closeButton}
              onClick={this.handleCloseAlert}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </>
    );
  }
}

OverSpeedScreen.propTypes = {
  history: PropTypes.shape({
    match: PropTypes.shape({
      params: PropTypes.shape({
        unitId: PropTypes.string,
      }),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string,
    }),
  }),
  getId: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  setProcessInProgress: PropTypes.func.isRequired,
};

OverSpeedScreen.defaultProps = {
  history: {},
  classes: {},
  match: {},
};

export default withStyles(styles)(OverSpeedScreen);
