import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SECRETS from './secrets';
import CONFIG from './config';

import logo from './caebes-logo.svg';

const styles = (theme) => ({
  root: {
    textAlign: 'center',
  },
  close: {
    position: 'absolute',
    right: 12,
    top: 'auto',
    padding: theme.spacing.unit / 2,
  },
  wrapper: {
    margin: theme.spacing.unit,
    display: 'inline-block',
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  caption: {
    color: 'rgba(255, 255, 255, 0.75)',
    textAlign: 'center',
  },
  link: {
    color: 'rgba(255, 255, 255, 0.75)',
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      pass: '',
      logged: false,
      loading: false,
      errorBarOpen: false,
    };
  }

  componentDidMount() {
    const { getId } = this.props;
    if (getId() > 0) {
      this.setState({ logged: true });
    }
  }

  handleCloseErrorBar = () => {
    this.setState({ errorBarOpen: false });
  };

  changeUser = (event) => this.setState({ user: event.target.value });

  changePass = (event) => this.setState({ pass: event.target.value });

  handleClick = (event) => {
    this.setState({ loading: true });
    const { user, pass } = this.state;
    const { logIn, getToken } = this.props;
    event.preventDefault();
    event.stopPropagation();
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post('/login', { usuario: user, pass })
      .then((response) => {
        logIn(
          response.data.id,
          response.data.token,
          response.data.ownerId,
          response.data.permissionLevel,
        );
        this.setState({ loading: false, logged: true });
        axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
        axios
          .patch(`/usuarios/${response.data.id}`, { privacyTerms: true })
          .then(() => {})
          .catch((error) => console.error(error.data));
      })
      .catch(() => {
        this.setState({ loading: false, errorBarOpen: true });
      });
  };

  render() {
    const {
      logged,
      user,
      pass,
      loading,
      errorBarOpen,
    } = this.state;
    const {
      classes,
    } = this.props;
    return (
      <div className="login">
        {logged && <Redirect to="/map" />}
        <div className="img">
          <img src={logo} alt="" />
          <h2>CAEBES</h2>
        </div>
        <div className="form">
          <label htmlFor="usuario" className="field">
            Usuario
            <input type="text" id="usuario" onChange={this.changeUser} value={user} />
          </label>
          <label htmlFor="password" className="field">
            Contraseña
            <input type="password" id="password" onChange={this.changePass} value={pass} />
          </label>
          <div className={classes.root}>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={loading}
                onClick={(event) => this.handleClick(event)}
              >
                Entrar
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
          <Typography variant="caption" color="textSecondary" className={classes.caption}>
            Al ingresar a esta aplicación acepto que leido y estoy de acuerdo con los
            {' '}
            <a href={CONFIG.CONDITIONSDOC} className={classes.link} target="_blank" rel="noopener noreferrer">
              términos y condiciones del servicio
            </a>
            {' '}
            y el
            {' '}
            <a href={CONFIG.PRIVACYDOC} className={classes.link} target="_blank" rel="noopener noreferrer">
              aviso de privacidad de datos personales
            </a>
            .
          </Typography>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={errorBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseErrorBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">El usuario o contraseña son incorrectos</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseErrorBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>

    );
  }
}

Login.propTypes = {
  getId: PropTypes.func.isRequired,
  logIn: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
};

Login.defaultProps = {
  classes: {},
};

export default withStyles(styles)(Login);
