import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import SECRETS from '../secrets';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
  },
  container: {
    position: 'relative',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  textField: {
    width: '100%',
  },
  counter: {
    float: 'right',
  },
});

class GeneralScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      loading: false,
      sentBarOpen: false,
      errorBarOpen: false,
    };
  }

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleChange = (event) => {
    if (event.target.value.length <= 255) {
      this.setState({ message: event.target.value });
    }
  };

  handleSend = () => {
    const { getId } = this.props;
    const userId = getId();
    const { message } = this.state;
    this.setState({ loading: true });
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post('/sugerencias', {
        usuario: userId,
        mensaje: message,
      })
      .then(() => {
        this.setState({
          loading: false,
          message: '',
          sentBarOpen: true,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false, errorBarOpen: true });
      });
  };

  handleCloseSentBar = () => {
    this.setState({ sentBarOpen: false });
  };

  handleCloseErrorBar = () => {
    this.setState({ errorBarOpen: false });
  };

  render() {
    const {
      message,
      loading,
      sentBarOpen,
      errorBarOpen,
    } = this.state;
    const { classes } = this.props;
    return (
      <>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="Back"
              onClick={this.goBack}
              className={classes.menuButton}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Quejas y sugerencias
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.container}>
          <TextField
            id="outlined-multiline-flexible"
            label="Mensaje"
            multiline
            rows="6"
            rowsMax="6"
            value={message}
            onChange={this.handleChange}
            className={classes.textField}
            margin="normal"
            helperText=""
            variant="outlined"
          />
          <Typography variant="caption" color="inherit" className={classes.counter}>
            {255 - message.length}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            disabled={loading}
            className={classes.button}
            onClick={(event) => this.handleSend(event)}
          >
            {!loading && 'Enviar'}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </main>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={sentBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseSentBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">El mensaje ha sido enviado.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseSentBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={errorBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseErrorBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Hubo un error al mandar el mensaje, intente nuevamente más tarde.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseErrorBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </>
    );
  }
}

GeneralScreen.propTypes = {
  classes: PropTypes.shape({
    grow: PropTypes.string,
  }),
  history: PropTypes.shape({
    match: PropTypes.shape({
      params: PropTypes.shape({
        unitId: PropTypes.string,
      }),
    }),
  }),
  getId: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

GeneralScreen.defaultProps = {
  classes: {},
  history: {},
};

export default withStyles(styles)(GeneralScreen);
