/* eslint-disable no-restricted-globals */
/* eslint-env browser */
/* eslint-disable no-undef */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from 'react-google-maps';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';

import SECRETS from '../secrets';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  button: {
    width: '100%',
    marginTop: theme.spacing.unit,
  },
  textField: {
    margin: theme.spacing.unit,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  zoneDetails: {
    position: 'relative',
    background: '#ffffff',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  closeButton: {
    padding: theme.spacing.unit / 2,
  },
});

const MapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: '100%' }} />,
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${SECRETS.MAPSAPIKEY}`,
    containerElement: <div className="geofence-map" />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)((props) => (
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={15}
    defaultCenter={props.position}
    options={{
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
    }}
    onCenterChanged={props.onMapCenterChanged}
  >
    <Circle
      center={props.currenMapCenter}
      radius={props.zoneRadius}
      ref={props.mapCircleRef}
      options={{
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      }}
      clickable={false}
      draggable={false}
      editable={false}
      onRadiusChanged={props.onRadiusChanged}
    />
    <Marker
      position={props.currenMapCenter}
      clickable={false}
      draggable={false}
      editable={false}
    />
  </GoogleMap>
));

const ZoneEditScreen = ({
  getId,
  getToken,
  setProcessInProgress,
  match: { params },
  classes,
  history,
}) => {
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [zoneId, setZoneId] = useState(-1);
  const [initialPosition, setInitialPosition] = useState({ lat: 19.702864, lng: -101.192345 });
  const [currentMapCenter, setCurrentMapCenter] = useState({ lat: 19.702864, lng: -101.192345 });
  const [mapRef, setMapRef] = useState(undefined);
  const [mapCircleRef, setMapCircleRef] = useState(undefined);
  const [zone, setZone] = useState({ radio: 200, nombre: '' });
  const [zoneRadius, setZoneRadius] = useState(200);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [staticMarker, setStaticMarker] = useState({
    position: { lat: 19.702864, lng: -101.192345 },
  });

  useEffect(() => {
    // Validate User is logged
    const userId = parseInt(getId(), 10);
    if (!userId || userId < 1) goToMapScreen();

    // Validate zoneId params URL, only on Edit Action exists
    let { zoneId: tempZoneId } = params;
    if (tempZoneId) {
      // Check if Edit Action and valid tempZoneId
      if (!isNumeric(tempZoneId)) goToMapScreen();

      setProcessInProgress(true);
      tempZoneId = parseInt(zoneId, 10);
      axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      axios
        .get(`/zonas/${tempZoneId}`)
        .then((response) => {
          const respZone = response.data;
          if (respZone) {
            const respInitialPosition = {
              lat: respZone.latitud,
              lng: respZone.longitud,
            };
            setComponentLoaded(true);
            setZoneId(tempZoneId);
            setZone(respZone);
            setZoneRadius(respZone.radio);
            setInitialPosition(respInitialPosition);
            setCurrentMapCenter(respInitialPosition);
            setStaticMarker({
              position: {
                lat: respZone.lastLat,
                lng: respZone.lastLng,
              },
            });
          } else {
            setAlertOpen(true);
            setAlertMessage('No se econtró la zona a editar');
            setTimeout(goToGeofenceScreen, 2000);
          }
          setProcessInProgress(false);
        });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setComponentLoaded(true);
          setCurrentMapCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
          setInitialPosition({ lat: position.coords.latitude, lng: position.coords.longitude });
          setStaticMarker({
            position: { lat: position.coords.latitude, lng: position.coords.longitude },
          });
        },
        (error) => console.error(error.message),
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
      );
    }
  });

  useEffect(() => {
    if (mapRef && mapCircleRef) {
      const bounds = mapCircleRef.getBounds();
      mapRef.fitBounds(bounds);
    }
  }, [zoneRadius]);

  isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

  // TODO: handleGoBack() on props
  handleGoBack = () => history.goBack();

  goToMapScreen = () => history.push('/map');

  goToZoneScreen = () => history.push('/user/zoneReport');

  handleCloseAlert = () => setAlertOpen(false);

  onMapMounted = (ref) => setMapRef(ref);

  onMapCenterChanged = () => setCurrentMapCenter(mapRef.getCenter());

  zoneNameChange = (event) => setZone({ ...zone, nombre: event.target.value });

  zoneRadiusChange = (event) => {
    const { target: value } = event;
    if (value) {
      setZone({ ...zone, radio: parseInt(value, 10) });
      if (parseInt(value, 10) >= 200 || value <= 1500) {
        setZoneRadius(parseInt(value, 10));
      }
    }
  };

  zoneFocusOut = () => {
    if (zone.radio === '' || zone.radio < 200) {
      setZone({ ...zone, radio: 200 });
      setZoneRadius(200);
    } else if (zone.radio > 1500) {
      setZone({ ...zone, radio: 1500 });
      setZoneRadius(1500);
    }
  };

  updateZone = () => {
    setProcessInProgress(true);

    if (zone.nombre === '') {
      setAlertOpen(true);
      setAlertMessage('Nombre no válido');
      setProcessInProgress(false);
      return;
    }

    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;

    // Check if Edit Action
    // TODO: UPDATE only if changes
    if (typeof zoneId !== 'undefined') {
      axios
        .patch(`/zonas/${zoneId}`, {
          ...zone,
          latitud: (typeof currenMapCenter.lat === 'function') ? currenMapCenter.lat() : currenMapCenter.lat,
          longitud: (typeof currenMapCenter.lng === 'function') ? currenMapCenter.lng() : currenMapCenter.lng,
        })
        .then(() => {
          // Return to Unit zones
          goToZoneScreen();
        })
        .catch((error) => {
          console.error(error.response.data.error);
          setProcessInProgress(false);
        });
    } else {
      axios
        .post('/zonas', {
          ...zone,
          idUsuario: parseInt(getId(), 10),
          activa: true,
        })
        .then(() => goToZoneScreen())
        .catch((error) => {
          console.error(error);
          setProcessInProgress(false);
        });
    }
  };

  return (
    componentLoaded && (
      <>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Back"
              onClick={handleGoBack}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {zoneId ? 'Editar Zona' : 'Agregar Zona'}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="map-container geofence-screen">
          <MapComponent
            position={initialPosition}
            currenMapCenter={currentMapCenter}
            zoneRadius={zoneRadius}
            onMapMounted={onMapMounted}
            mapCircleRef={(circle) => setMapCircleRef(circle)}
            onMapCenterChanged={onMapCenterChanged}
            onRadiusChanged={onRadiusChanged}
            staticMarker={staticMarker}
          />
        </div>
        <div className={classes.zoneDetails}>
          <Typography component="h3" variant="body2" color="inherit">
            Zona de reporte
          </Typography>
          <form className={classes.form} noValidate autoComplete="off">
            <TextField
              id="name"
              label="Nombre"
              className={classes.textField}
              style={{ width: 'calc(70% - 16px)' }}
              value={zone.nombre}
              onChange={zoneNameChange}
              margin="normal"
            />
            <TextField
              id="radius"
              label="Radio"
              type="number"
              value={(zone) ? zone.radio : 200}
              onChange={zoneRadiusChange}
              onBlur={zoneFocusOut}
              className={classes.textField}
              style={{ width: 'calc(30% - 16px)' }}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
          </form>
          <Button
            variant="contained"
            className={classes.button}
            color="secondary"
            onClick={updateZone}
          >
            Guardar
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={alertOpen}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          ContentProps={{
            'aria-describedby': 'message-id',
            headlineMapping: {
              body1: 'div',
              body2: 'div',
            },
          }}
          message={<span id="message-id">{alertMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.closeButton}
              onClick={handleCloseAlert}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </>
    )
  );
};

ZoneEditScreen.propTypes = {
  history: PropTypes.shape({
    match: PropTypes.shape({
      params: PropTypes.shape({
        unitId: PropTypes.string,
      }),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string,
    }),
  }),
  getId: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  setProcessInProgress: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

ZoneEditScreen.defaultProps = {
  history: {},
  classes: {},
  match: {},
};

export default withStyles(styles)(ZoneEditScreen);
