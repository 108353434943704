/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-return */

// Hard-coded, replace with your public key
const vapidPublicKey = 'BKPlyWNyCDCL5L-BQtoKNh0D_Y2crIGnriamGe42co52BUDpaBwaUALnGzKnqPUIcS2B-E_YqTdO95rOR9fSQIk';

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// This method handles the removal of subscriptionId
// in Chrome 44 by concatenating the subscription Id
// to the subscription endpoint
function subscriptionIdWorkaround(pushSubscription) {
  // Make sure we only mess with GCM
  let mergedEndpoint = pushSubscription.endpoint;

  if (pushSubscription.endpoint.indexOf('https://android.googleapis.com/gcm/send') === 0) {
    // Chrome 42 + 43 will not have the subscriptionId attached
    // to the endpoint.
    if (pushSubscription.subscriptionId
      && pushSubscription.endpoint.indexOf(pushSubscription.subscriptionId) === -1) {
      // Handle version 42 where you have separate subId and Endpoint
      mergedEndpoint = `${pushSubscription.endpoint}/${pushSubscription.subscriptionId}`;
    }
  }

  const endpointSections = mergedEndpoint.split('/');
  const subscriptionId = endpointSections[endpointSections.length - 1];

  return subscriptionId;
}

export function subscribePush() {
  return new Promise((resolve, reject) => {
    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration) => {
        const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
        serviceWorkerRegistration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey,
          })
          .then((subscription) => {
            // The subscription was success
            // Return pushSubscription obj and subscriptionId
            const subscriptionId = subscriptionIdWorkaround(subscription);
            resolve({ subscription, subscriptionId });
          })
          .catch((error) => {
            if (Notification.permission === 'denied') {
              // The user denied the notification permission which
              // means we failed to subscribe and the user will need
              // to manually change the notification permission to
              // subscribe to push messages
              console.error('Permission for Notifications was denied');
            } else {
              // A problem occurred with the subscription
              console.error('Unable to subscribe to push.', error);
            }
            reject(error);
          });
      });
  });
}

export function unsubscribePush() {
  return new Promise((resolve, reject) => {
    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration) => {
        // To unsubscribe from push messaging, you need get the
        // subcription object, which you can call unsubscribe() on.
        serviceWorkerRegistration.pushManager
          .getSubscription()
          .then((pushSubscription) => {
            // Check we have a subscription to unsubscribe
            if (pushSubscription) {
              pushSubscription
                .unsubscribe()
                .then(() => { resolve(); })
                .catch((error) => {
                  // We failed to unsubscribe
                  console.error('Unsubscription error: ', error);
                  reject(error);
                });
            } else {
              resolve();
            }
          })
          .catch((error) => {
            console.error('Error thrown while unsubscribing from push messaging: ', error);
          });
      });
  });
}

export function getSubscriptionId() {
  return new Promise((resolve, reject) => {
    // We need the service worker registration to check for a subscription
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      // Do we already have a push message subscription?
      serviceWorkerRegistration.pushManager.getSubscription()
        .then((subscription) => {
          let subscriptionId = null;
          if (subscription) subscriptionId = subscriptionIdWorkaround(subscription);
          resolve(subscriptionId);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

// Once the service worker is registered set the initial state
function initialiseState() {
  // Are Notifications supported in the service worker?
  if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
    console.error('Notifications aren\'t supported.');
    return;
  }

  // Check the current Notification permission.
  // If its denied, it's a permanent block until the
  // user changes the permission
  if (Notification.permission === 'denied') {
    console.error('The user has blocked notifications.');
    return;
  }

  // Check if push messaging is supported
  if (!('PushManager' in window)) {
    console.error('Push messaging isn\'t supported.');
    return;
  }

  // TODO:
  // 1. Sync always current device subscription,
  // for that we need to identify device, maybe MAC ADDRESS

  // We need the service worker registration to check for a subscription
  // navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
  //   // Do we already have a push message subscription?
  //   serviceWorkerRegistration.pushManager
  //     .getSubscription()
  //     .then((subscription) => {
  //       // We aren’t subscribed to push, so nothing to do
  //       if (!subscription) return;

  //       // Keep your server in sync with the latest subscription
  //       sendSubscriptionToServer(subscription);
  //     })
  //     .catch((error) => {
  //       console.error('Error during getSubscription()', error);
  //     });
  // });
}

export default function init() {
  window.addEventListener('load', () => {
    // Check that service workers are supported, if so, progressively
    // enhance and add push messaging support, otherwise continue without it.
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('./push-worker.js')
        .then(initialiseState)
        .catch((error) => {
          console.error('Error during service push worker registration:', error);
        });
    } else {
      console.error('Service workers aren\'t supported in this browser.');
    }
  });
}
