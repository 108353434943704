import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MainIcon from '@material-ui/icons/Mail';
// import SmartPhoneIcon from '@material-ui/icons/Smartphone';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';

import { subscribePush, unsubscribePush, getSubscriptionId } from './registerPushServiceWorker';

import SECRETS from './secrets';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  listContainer: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: '4%',
    marginRight: '4%',
    marginTop: '8px',
    marginBottom: '16px',
    width: '92%',
  },
  textGutter: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  saveButton: {
    marginLeft: '4%',
    marginRight: '4%',
    width: '92%',
  },
  subheader: {
    position: 'relative',
  },
});

const ShowPasswordIconStyle = {
  marginRight: '4%',
  fontSize: '37px',
  zIndex: 99,
  position: 'absolute',
  right: '13px',
  top: '19px',
  color: '#666',
};

const SettingsScreen = ({
  getId,
  getToken,
  setProcessInProgress,
  classes,
}) => {
  const [alertNotificationsActived, setAlertNotificationsActived] = useState([0, 0]);
  const [pushNotificationsActived, setPushNotificationsActived] = useState(false);
  const [goToMapScreen, setGoToMapScreen] = useState(false);
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newMail, setNewMail] = useState('');
  const [savedBarOpen, setSavedBarOpen] = useState(false);
  const [errorBarOpen, setErrorBarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (getId() < 1) return;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/usuarios/${getId()}`)
      .then((response) => {
        const {
          alarmNotifications,
          telefono,
          correo,
        } = response.data;
        if (alarmNotifications > 0) {
          const notificationValues = (`0${(parseInt(alarmNotifications, 10)).toString(2)}`).substr(-2);
          // 10 => 2, Email
          const temp = [0, 0];
          if (notificationValues.charAt(0) === '1') temp[0] = 1;
          // [0,1] => 1, WhatsApp
          if (notificationValues.charAt(1) === '1') temp[1] = 1;
          setAlertNotificationsActived(temp);
        }
        setNewMail(correo);
        setMail(correo);
        setNewPhone(telefono);
        setPhone(telefono);
      })
      .then(() => {
        getSubscriptionId()
          .then((subscriptionId) => {
            // The device has a susbscription, validate in DB
            if (subscriptionId) {
              const encodedId = encodeURI(subscriptionId);
              axios
                .get(`notifications/subscriptions/${encodedId}`)
                .then((subscription) => setPushNotificationsActived(subscription.data !== null));
            } else {
              setPushNotificationsActived(false);
            }
          });
      })
      .catch((e) => console.error(e));
  }, []);

  const goBack = () => setGoToMapScreen(true);

  const updateUserSettings = (properties) => (
    new Promise((resolve, reject) => {
      axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      axios
        .patch(`/usuarios/${getId()}`, properties)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    })
  );

  const updateAlertNotifications = (value) => (event) => {
    const newAlertNotificationsActived = [...alertNotificationsActived];

    newAlertNotificationsActived[value] = event.target.checked ? 1 : 0;

    const alarmNotificationsValue = parseInt(newAlertNotificationsActived.join(''), 2);
    updateUserSettings({
      alarmNotifications: alarmNotificationsValue,
    })
      .then(() => {
        setAlertNotificationsActived(newAlertNotificationsActived);
      });
  };

  const handleUserPushNotifications = ({ target: checked }) => {
    setProcessInProgress(true);
    if (checked) {
      subscribePush()
        .then((pushSubscription) => {
          const subscription = {
            idUsuario: getId(),
            suscripcion: JSON.stringify(pushSubscription.subscription),
            suscripcionId: pushSubscription.subscriptionId,
          };

          axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
          axios.defaults.baseURL = SECRETS.SERVERURL;
          axios
            .post('notifications/subscribe', subscription)
            .then(() => {
              // TODO: update user preference pushNotifications
              setPushNotificationsActived(checked);
              setProcessInProgress(false);
            })
            .catch((error) => {
              console.error(error);
              setProcessInProgress(false);
            });
        })
        .catch((error) => {
          console.error('Unable to subscribe to push.', error);
          setProcessInProgress(false);
        });
    } else {
      getSubscriptionId()
        .then((subscriptionId) => {
          axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
          axios.defaults.baseURL = SECRETS.SERVERURL;
          axios
            .delete(`notifications/unsubscribe/${subscriptionId}`)
            .then((deleted) => {
              if (deleted) {
                unsubscribePush()
                  .then(() => {
                    updateUserSettings({
                      pushNotifications: checked,
                      subscription: 'null',
                    });
                  })
                  .then(() => {
                    setPushNotificationsActived(checked);
                    setProcessInProgress(false);
                  });
              }
              setProcessInProgress(false);
            })
            .catch((error) => {
              console.error(error);
              setProcessInProgress(false);
            });
        });
    }
  };

  const handleSave = () => {
    let newValues = {};
    if (newPass !== '') {
      newValues = { pass: newPass };
    }
    if (newPhone !== phone) {
      newValues = { ...newValues, telefono: newPhone };
    }
    if (newMail !== mail) {
      newValues = { ...newValues, correo: newMail };
    }

    if (Object.keys(newValues).length > 0) {
      setLoading(true);
      axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      axios
        .patch(`/usuarios/${getId()}`, newValues)
        .then(() => {
          setPhone(newPhone);
          setMail(newMail);
          setSavedBarOpen(true);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setErrorBarOpen(true);
          console.error(e);
        });
    }
  };

  const changeField = (field, e) => {
    if (field === 'password') {
      setNewPass(e.target.value);
    } else if (field === 'mail') {
      setNewMail(e.target.value);
    } else if (field === 'phone') {
      setNewPhone(e.target.value);
    }
  };

  const handleCloseSavedBar = () => setSavedBarOpen(false);

  const handleCloseErrorBar = () => setErrorBarOpen(false);

  if (getId() < 1) return <Redirect to="/" />;
  if (goToMapScreen) return <Redirect to="/map" />;
  return (
    <div>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Back"
              onClick={goBack}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Configuración
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.listContainer}>
        <List
          component="nav"
          subheader={<ListSubheader className={classes.subheader}>Usuario</ListSubheader>}
        >
          <Typography variant="subtitle1" color="inherit" className={classes.textGutter}>
            Cambiar contraseña
          </Typography>
          <div style={{ position: 'relative' }}>
            <TextField
              id="outlined-password-input"
              label="Contraseña Nueva"
              className={classes.textField}
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={newPass}
              placeholder="******"
              onChange={(e) => changeField('password', e)}
            />
            {showPassword
              ? (
                <VisibilityOffIcon
                  style={ShowPasswordIconStyle}
                  onClick={() => setShowPassword(false)}
                />
              )
              : (
                <VisibilityIcon
                  style={ShowPasswordIconStyle}
                  onClick={() => setShowPassword(true)}
                />
              )}
          </div>
          <Typography variant="subtitle1" color="inherit" className={classes.textGutter}>
            Cambiar número telefónico
          </Typography>
          <TextField
            id="outlined-phone-input"
            label="Teléfono"
            className={classes.textField}
            type="number"
            fullWidth
            margin="normal"
            variant="outlined"
            value={newPhone}
            onChange={(e) => changeField('phone', e)}
          />
          <Typography variant="subtitle1" color="inherit" className={classes.textGutter}>
            Cambiar correo
          </Typography>
          <TextField
            id="outlined-password-input"
            label="Correo"
            className={classes.textField}
            type="mail"
            autoComplete="current-password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={newMail}
            onChange={(e) => changeField('mail', e)}
          />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            className={classes.saveButton}
            onClick={(event) => handleSave(event)}
          >
            {!loading && 'Guardar'}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </List>
        <List
          component="nav"
          subheader={<ListSubheader>Notificaciones</ListSubheader>}
        >
          <ListItem>
            <ListItemIcon>
              <MainIcon />
            </ListItemIcon>
            <ListItemText primary="Correo electrónico" />
            <ListItemSecondaryAction>
              <Switch
                onChange={updateAlertNotifications(0)}
                checked={alertNotificationsActived[0] === 1}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <WebAssetIcon />
            </ListItemIcon>
            <ListItemText primary="Notificaciones Web" />
            <ListItemSecondaryAction>
              <Switch
                onChange={handleUserPushNotifications}
                checked={pushNotificationsActived}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Divider />
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={savedBarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSavedBar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Los cambios se han guardado.</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleCloseSavedBar}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorBarOpen}
        autoHideDuration={6000}
        onClose={handleCloseErrorBar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Hubo un error al mandar el mensaje, intente nuevamente más tarde.</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleCloseErrorBar}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

SettingsScreen.propTypes = {
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  setProcessInProgress: PropTypes.func.isRequired,
};

SettingsScreen.defaultProps = {
  classes: {},
};

export default withStyles(styles)(SettingsScreen);
