/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import * as moment from 'moment';
import 'moment/locale/es';

import isBeforeDay from '../Utils/isBeforeDay';

import SECRETS from '../secrets';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
  },
  container: {
    position: 'relative',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    height: '86vh',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
  },
  itemSpacing: {
    marginTop: 20,
  },
  buttonBottom: {
    marginTop: '20px',
    width: '91%',
  },
  description: {
    paddingTop: '20px',
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class GeneralScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUnidades: [],
      unidades: [],
      startDate: null,
      endDate: null,
      focusedInput: null,
      loading: false,
      errorBarOpen: false,
    };
  }

  componentDidMount() {
    const { getId } = this.props;
    const userId = getId();
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/unidades/usuarios/${userId}`)
      .then((resp) => {
        this.setState({ unidades: resp.data });
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  }

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleSelect = (event) => {
    this.setState({ selectedUnidades: event.target.value });
  };

  handleDateChange = ({ startDate, endDate }) => (
    this.setState({ startDate, endDate })
  );

  handleExport = () => {
    const {
      selectedUnidades,
      unidades,
      startDate,
      endDate,
    } = this.state;

    const unitsToQuery = [];

    selectedUnidades.forEach((name) => {
      const index = unidades.findIndex((unidad) => unidad.unidad === name);
      unitsToQuery.push(unidades[index].idUnidad);
    });

    this.setState({ loading: true });

    const { getToken } = this.props;

    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post('sheets/reportegeneral', {
        unidades: JSON.stringify(unitsToQuery),
        fechaI: startDate.format('DD-MM-YYYY'),
        fechaF: endDate.format('DD-MM-YYYY'),
      }, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Reporte General.xlsx');
        document.body.appendChild(link);
        link.click();
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ errorBarOpen: true, loading: false });
        console.error(error);
      });
  };

  handleCloseErrorBar = () => {
    this.setState({ errorBarOpen: false });
  };

  render() {
    const {
      selectedUnidades,
      unidades,
      startDate,
      endDate,
      focusedInput,
      loading,
      errorBarOpen,
    } = this.state;
    const { classes } = this.props;
    return (
      <>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="Back"
              onClick={this.goBack}
              className={classes.menuButton}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Reporte General
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.container}>
          <Typography color="inherit" className={classes.description}>
            {'Selecciona la o las unidades y el rango de fechas para obtener un archivo xls con consumo '
              + 'de combustible, distancia recorrida y velocidad máxima de las unidades, separado por fecha.'}
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox">Unidades</InputLabel>
            <Select
              multiple
              value={selectedUnidades}
              onChange={this.handleSelect}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {unidades.map((unidad) => (
                <MenuItem key={unidad.idUnidad} value={unidad.unidad}>
                  <Checkbox checked={selectedUnidades.indexOf(unidad.unidad) > -1} />
                  <ListItemText primary={unidad.unidad} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DateRangePicker
            startDatePlaceholderText="Inicio"
            endDatePlaceholderText="Fin"
            isOutsideRange={(day) => isBeforeDay(day, moment())}
            minimumNights={0}
            noBorder
            startDate={startDate}
            startDateId="date01"
            endDate={endDate}
            endDateId="date02"
            onDatesChange={this.handleDateChange}
            focusedInput={focusedInput}
            onFocusChange={(focusedInputT) => this.setState({ focusedInput: focusedInputT })}
            numberOfMonths={1}
            renderDayContents={(day) => moment(day).format('D')}
          />
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonBottom}
            disabled={loading || selectedUnidades.length === 0 || !startDate || !endDate}
            onClick={(event) => this.handleExport(event)}
          >
            {!loading && 'Exportar'}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </main>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={errorBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseErrorBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Hubo un error al pedir los datos, intente nuevamente más tarde.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseErrorBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </>
    );
  }
}

GeneralScreen.propTypes = {
  classes: PropTypes.shape({
    grow: PropTypes.string,
  }),
  history: PropTypes.shape({
    match: PropTypes.shape({
      params: PropTypes.shape({
        unitId: PropTypes.string,
      }),
    }),
  }),
  getId: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

GeneralScreen.defaultProps = {
  classes: {},
  history: {},
};

export default withStyles(styles)(GeneralScreen);
