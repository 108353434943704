const SECRETS = {
  // SERVERURL: 'http://localhost:7000',
  // SERVERURL: 'https://caebes.com:8000',
  SERVERURL: 'https://api.caebes.com:8001', // backend-test
  // SERVERURL: 'https://api56.caebes.com:8002', // backend-complete
  // SERVERURL: 'https://uber.caebes.com:8000', // backend-uber
  // SERVERURL: 'https://uber.caebes.com:8001', // backend-uber-test
  MAPSAPIKEY: 'AIzaSyAvcOTqJyMau74OQdBHz4puiG9rkVLM8VU',
  // SOCKETURL: 'ws://localhost:9001',
  // SOCKETURL: 'wss://api56.caebes.com:9001', // backend-test
  SOCKETURL: 'wss://api56.caebes.com:9002', // backend-complete
  SOCKETCIUDADANOS: 'wss://api57.caebes.com:9500', // socket ciudadanos production
  // SOCKETCIUDADANOS: 'wss://api57.caebes.com:9501', // testing socket
  TIMEDELAY: (1000 * 60 * 60),
  CIUDADANOSKEY: 'Xl71avvZ!^qag7GWd2^xr86qc!S2V^',
  CIUDADANOSSERVER: 'https://api57.caebes.com:8000',
};

export default SECRETS;
