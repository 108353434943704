import React from 'react';
import PropTypes from 'prop-types';
import { InfoWindow } from 'react-google-maps';
import FA from 'react-fontawesome';

import CustomMarker from './CustomMarker';

const roundCoords = (coord) => {
  let tmp = coord * 1000000;
  tmp = Math.round(tmp);
  return tmp / 1000000;
};

const MarkerUnidad = ({
  point,
  handleClick,
  enablePanorama,
  setPanorama,
  visible,
  scale,
}) => (
  <CustomMarker
    id={point.idUnidad}
    position={{ lat: point.latitud, lng: point.longitud }}
    info={handleClick}
    label={point.unidad}
    motor={point.motor}
    locked={point.electricity}
    personal={point.gpsModel === 'qbit'}
    visible={visible}
    scale={scale}
  >
    {point.info && (
      <InfoWindow>
        <div style={{ backgroundColor: 'white', opacity: 0.85, padding: '5px' }}>
          <div style={{ fontSize: '0.9em', fontColor: '#333' }}>
            <p>
              unidad:
              {' '}
              {point.unidad}
            </p>
            <p>
              fecha:
              {' '}
              {point.fecha}
            </p>
            <p>
              hora:
              {' '}
              {point.hora}
            </p>
            <p>
              velocidad:
              {' '}
              {point.velocidad}
              km/h
            </p>
            <div
              className="street-image"
              onClick={() => {
                if (enablePanorama) {
                  setPanorama({
                    lat: roundCoords(point.latitud),
                    lng: roundCoords(point.longitud),
                  });
                }
              }}
              onKeyPress={() => {}}
              role="button"
              tabIndex={0}
            >
              <FA name="street-view" size="4x" className="street-view-icon" />
              <span className="text">Street View</span>
            </div>
          </div>
        </div>
      </InfoWindow>
    )}
  </CustomMarker>
);

MarkerUnidad.propTypes = {
  point: PropTypes.shape({
    unidad: PropTypes.string.isRequired,
    latitud: PropTypes.number,
    longitud: PropTypes.number,
    velocidad: PropTypes.number.isRequired,
    info: PropTypes.bool.isRequired,
    idUnidad: PropTypes.number.isRequired,
    motor: PropTypes.bool,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  enablePanorama: PropTypes.bool.isRequired,
  setPanorama: PropTypes.func.isRequired,
};

export default MarkerUnidad;
