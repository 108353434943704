import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as Axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FA from 'react-fontawesome';

import SECRETS from '../secrets';

const axios = Axios.create({
  baseURL: SECRETS.SERVERURL,
});

const CELL_WIDTH = '150px';

const container = {
  height: '100vh',
  width: '100vw',
  backgroundColor: '#fff',
  color: '#848484',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const phase1 = {
  width: '100%',
  height: '100%',
};

const table = {
  display: 'block',
  width: '99vw',
  height: '85vh',
  overflow: 'scroll',
  scrollbarColor: '#ccc #333',
  scrollbarWidth: 'auto',
};

const cellSize = {
  minWidth: CELL_WIDTH,
  maxWidth: CELL_WIDTH,
  textAlign: 'center',
};

const headerText = {
  ...cellSize,
  fontSize: '12px',
  overflow: 'hidden',
};

const contentText = {
  ...cellSize,
  fontSize: '10px',
  margin: '5px 0',
  overflow: 'hidden',
  padding: '10px 0',
};

const actionText = {
  ...contentText,
  textDecoration: 'underline',
  cursor: 'pointer',
};

const tableContent = {
  display: 'block',
};

const row = {
  width: '100%',
};

const inputStyle = {
  width: CELL_WIDTH,
  fontSize: '12px',
  textAlign: 'center',
};

const rowStyle = (index) => {
  if (index % 2 === 0) {
    return {
      ...row,
      backgroundColor: 'rgb(244,244,244)',
    };
  }
  return {
    ...row,
  };
};

const eliminadoStyle = (eliminado) => {
  if (eliminado) {
    return {
      ...contentText,
      backgroundColor: '#f9c6c6',
    };
  }
  return {
    ...contentText,
    backgroundColor: '#bce3bc',
  };
};

const backStyle = {
  width: '90%',
};

const UserUnitTableScreen = ({
  history,
  cookies,
  getId,
}) => {
  const [usuarios, setUsuarios] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [usrUnits, setUsrUnits] = useState([]);
  const [usuario, setUsuario] = useState(0);
  const [unidad, setUnidad] = useState('');
  const [msg, setMsg] = useState('');

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
    axios
      .get('/usuarios/all')
      .then((res) => {
        setUsuarios(res.data);
      })
      .catch((e) => {
        setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (usuarios.length > 0) {
      axios
        .get('/unidades')
        .then((resu) => {
          setUnidades(resu.data);
        })
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuvo más tarde.');
          console.error(e);
        });
    }
  }, [usuarios]);

  useEffect(() => {
    if (usuarios && usuarios.length > 0 && unidades && unidades.length > 0) {
      axios
        .get('/usuariounidades')
        .then((resu) => {
          setUsrUnits(resu.data
            .map((uu) => ({ ...uu, editable: false }))
            .sort((a, b) => {
              const idUnidada = parseInt(a.idUnidad, 10);
              const idUnidadb = parseInt(b.idUnidad, 10);
              const idUsuarioa = parseInt(a.idUsuario, 10);
              const idUsuariob = parseInt(b.idUsuario, 10);
              if (idUnidada === idUnidadb) {
                const foundUsera = usuarios.find((u) => u.idUsuario === idUsuarioa);
                const foundUserb = usuarios.find((u) => u.idUsuario === idUsuariob);
                if (typeof foundUsera === 'undefined' || typeof foundUserb === 'undefined') {
                  return 1;
                }
                return foundUserb.usuario.localeCompare(foundUserb.usuario);
              }
              const foundUnita = unidades.find((u) => u.idUnidad === idUnidada);
              const foundUnitb = unidades.find((u) => u.idUnidad === idUnidadb);
              if (typeof foundUnita === 'undefined' || typeof foundUnitb === 'undefined') {
                return 1;
              }
              return foundUnita.unidad
                .localeCompare(foundUnitb.unidad);
            }));
        })
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
          console.error(e);
        });
    }
  }, [unidades]);

  const validateUnit = (unit) => {
    if (unit.idUnidad <= 0) {
      setMsg('El id de la unidad debe ser mayor a 0.');
      return false;
    }
    if (unit.idUsuario <= 0) {
      setMsg('El id del usuario debe ser mayor a 0.');
      return false;
    }
    return true;
  };

  const register = async () => {
    if (validateUnit({
      idUsuario: parseInt(usuario, 10),
      idUnidad: parseInt(unidad, 10),
    })) {
      const newUserUnit = {
        idUsuario: parseInt(usuario, 10),
        idUnidad: parseInt(unidad, 10),
      };

      axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
      const res = await axios
        .post('/usuariounidades', newUserUnit)
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
          console.error(e);
        });

      if (res.status === 200) {
        if (res.data.success) {
          const newUsuarioUnidades = [...usrUnits];
          newUsuarioUnidades.push({ ...res.data.mod, editable: false });
          setUsrUnits(newUsuarioUnidades
            .sort((a, b) => {
              const idUnidada = parseInt(a.idUnidad, 10);
              const idUnidadb = parseInt(b.idUnidad, 10);
              const idUsuarioa = parseInt(a.idUsuario, 10);
              const idUsuariob = parseInt(b.idUsuario, 10);
              if (idUnidada === idUnidadb) {
                const foundUsera = usuarios.find((u) => u.idUsuario === idUsuarioa);
                const foundUserb = usuarios.find((u) => u.idUsuario === idUsuariob);
                if (typeof foundUsera === 'undefined' || typeof foundUserb === 'undefined') {
                  return 1;
                }
                return foundUserb.usuario.localeCompare(foundUserb.usuario);
              }
              const foundUnita = unidades.find((u) => u.idUnidad === idUnidada);
              const foundUnitb = unidades.find((u) => u.idUnidad === idUnidadb);
              if (typeof foundUnita === 'undefined' || typeof foundUnitb === 'undefined') {
                return 1;
              }
              return foundUnita.unidad
                .localeCompare(foundUnitb.unidad);
            }));
          setMsg('Registrado con éxito.');
          setUsuario('');
          setUnidad('');
        } else {
          setMsg(res.data.message);
        }
      }
    }
  };

  const goBack = () => history.goBack();

  const editar = async (index) => {
    const newUsrUnits = [...usrUnits];
    if (!newUsrUnits[index].editable) {
      newUsrUnits[index].editable = true;
      setUsrUnits(newUsrUnits);
    } else {
      const modUsrUnit = {
        idUnidad: newUsrUnits[index].idUnidad,
        idUsuario: newUsrUnits[index].idUsuario,
      };
      axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
      const res = await axios
        .patch(`/usuariounidades/${newUsrUnits[index].id}`, modUsrUnit)
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
          console.error(e);
        });
      if (res.status && res.status === 200) {
        if (res.data.count.length === 1) {
          setMsg('Modificado con éxito.');
          newUsrUnits[index].editable = false;
          setUsrUnits(newUsrUnits);
        } else {
          setMsg('Hubo un error, intenta nuevamente');
        }
      }
    }
  };

  const getUser = (id, property) => {
    const foundUser = usuarios.find((u) => u.idUsuario === id);
    if (typeof foundUser === 'undefined' || typeof foundUser[property] === 'undefined') {
      return 'no existe';
    }
    return usuarios.find((u) => u.idUsuario === id)[property];
  };

  const getUnidad = (id, property) => {
    const foundUnit = unidades.find((u) => u.idUnidad === id);
    if (typeof foundUnit === 'undefined' || typeof foundUnit[property] === 'undefined') {
      return 'no existe';
    }
    return unidades.find((u) => u.idUnidad === id)[property];
  };

  const handleChange = (e, index, property) => {
    const newUsrUnits = [...usrUnits];
    const newUsrUnit = usrUnits[index];
    newUsrUnit[property] = e.target.value;
    newUsrUnits[index] = newUsrUnit;
    setUsrUnits(newUsrUnits);
  };

  if (parseInt(getId(), 10) !== 1) return <Redirect to="/" />;

  return (
    <div style={container}>
      <AppBar position="static">
        <Toolbar>
          <FA
            className="menu-btn"
            name="chevron-left"
            onClick={goBack}
          />
          <Typography style={backStyle} variant="h6" color="inherit">
            CAEBES
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={phase1}>
        <h2>Usuario Unidades</h2>
        <table style={table}>
          <thead>
            <tr>
              <th style={headerText}>
                id
              </th>
              <th style={headerText}>
                idUsuario
              </th>
              <th style={headerText}>
                idUnidad
              </th>
              <th style={headerText}>
                Editar/Guardar
              </th>
              <th style={headerText}>
                Usuario
              </th>
              <th style={headerText}>
                unidad
              </th>
            </tr>
          </thead>
          <tbody style={tableContent}>
            <tr style={rowStyle(-1)}>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="number" name="usuario" id="usuario-new" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
              </td>
              <td style={contentText}>
                <input style={inputStyle} type="number" name="unidad" id="unidad-new" value={unidad} onChange={(e) => setUnidad(e.target.value)} />
              </td>
              <td style={actionText} onClick={register} role="button" tabIndex={0} onKeyDown={() => {}}>
                Guardar
              </td>
              <td style={contentText}>
                -
              </td>
              <td style={contentText}>
                -
              </td>
            </tr>
            {usrUnits.map((uu, index) => {
              if (!uu.editable) {
                return (
                  <tr style={rowStyle(index)} key={uu.id}>
                    <td style={contentText}>
                      {uu.id}
                    </td>
                    <td style={contentText}>
                      {uu.idUsuario}
                    </td>
                    <td style={contentText}>
                      {uu.idUnidad}
                    </td>
                    <td style={actionText} onClick={() => editar(index)}>
                      {uu.editable ? 'Guardar' : 'Editar'}
                    </td>
                    <td style={eliminadoStyle(getUser(uu.idUsuario, 'eliminado'))}>
                      {getUser(uu.idUsuario, 'usuario')}
                    </td>
                    <td style={eliminadoStyle(getUnidad(uu.idUnidad, 'eliminado'))}>
                      {getUnidad(uu.idUnidad, 'unidad')}
                    </td>
                  </tr>
                );
              }
              // is editable
              return (
                <tr style={rowStyle(index)} key={uu.id}>
                  <td style={contentText}>
                    {uu.id}
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="text" name="usuario" id={`usuario-${uu.idUsuario}`} value={uu.idUsuario} onChange={(e) => handleChange(e, index, 'idUsuario')} />
                  </td>
                  <td style={contentText}>
                    <input style={inputStyle} type="text" name="unidad" id={`unidad-${uu.idUnidad}`} value={uu.idUnidad} onChange={(e) => handleChange(e, index, 'idUnidad')} />
                  </td>
                  <td style={actionText} onClick={() => editar(index)}>
                    {uu.editable ? 'Guardar' : 'Editar'}
                  </td>
                  <td style={eliminadoStyle(getUser(uu.idUsuario, 'eliminado'))}>
                    {getUser(uu.idUsuario, 'usuario')}
                  </td>
                  <td style={eliminadoStyle(getUnidad(uu.idUnidad, 'eliminado'))}>
                    {getUnidad(uu.idUnidad, 'unidad')}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={msg !== ''}
        autoHideDuration={6000}
        onClose={() => setMsg('')}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
      />
    </div>
  );
};

export default UserUnitTableScreen;
