import moment from 'moment';

export default function getNextDate(today, user) {
  const hoy = moment(today, 'YYYY-MM-DD');
  const warnDate = moment(user.fechaAviso, 'YYYY-MM-DD');
  const upDate = moment(warnDate);
  while (upDate.startOf('day').isSameOrBefore(hoy.startOf('day'))) {
    if (upDate.date() === user.diaCorte) upDate.add(5, 'days');
    else {
      let dateDiff = 0;
      while (upDate.date() !== user.diaCorte) {
        upDate.add(1, 'days');
        dateDiff += 1;
      }
      if (dateDiff > 5) {
        upDate.add(user.periodoMeses - 1, 'months').subtract(5, 'days');
      }
    }
  }
  return upDate.format('YYYY-MM-DD');
}
